const loadingContentDoneReducer = (state = false, action) => {
    switch (action.type) {
      case "LOADING_CONTENT_DONE":
        return true;      
      default:
        return state;
    }
  };
  
  export default loadingContentDoneReducer;
  