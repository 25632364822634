import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {OpenSnackbar} from "../../../../services/redux/actions";

function Toolbar({ title, slug }) {

    const currentIssueColors = useSelector((state) => state.latestIssue);
    const [showSharingMenu, setShowSharingMenu] = useState(false)
    const toggleSharingMenu = () => {
        setShowSharingMenu(!showSharingMenu)
    }
    const sharingUrl = "https://reportagen.com/news/" + slug;
    const dispatch = useDispatch();
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(sharingUrl);
            dispatch(OpenSnackbar("Link kopiert"));
        } catch (err) {
            //console.log('Failed to copy: ', err);
        }
    }
    const handleClickSharing = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: title,
                    url: sharingUrl,
                })
        } else {
            toggleSharingMenu();
        }
    };

    return (
        <div className="toolbox">
            <div className="toolbar flex-dir-column" style={{ background: currentIssueColors.backgroundColorMedium }}>
                <div className={`toolbar__submenu text-center ${!showSharingMenu && `hide`}`}>
                    <div className="description">Teilen</div>
                    <a target="_blank" rel="noopener noreferrer" onClick={toggleSharingMenu}
                       href={`mailto:?subject=${title}&body=${sharingUrl}`}>E-Mail</a>
                    <a target="_blank" rel="noopener noreferrer" onClick={toggleSharingMenu}
                       href={`https://api.whatsapp.com/send?text=${sharingUrl}`}>WhatsApp</a>
                    <a target="_blank" rel="noopener noreferrer" onClick={toggleSharingMenu}
                       href={`https://www.facebook.com/sharer/sharer.php?u=${sharingUrl}`}>Facebook</a>
                    <a target="_blank" rel="noopener noreferrer" onClick={toggleSharingMenu}
                       href={`https://www.linkedin.com/sharing/share-offsite/?url=${sharingUrl}`}>LinkedIn</a>
                    <a target="_blank" rel="noopener noreferrer" onClick={copyToClipboard}>Link kopieren</a>
                </div>
                <a className="toolbar__icon" style={{marginLeft: "auto"}} onClick={() => handleClickSharing()}>
                    {showSharingMenu ?
                    <>
                    <svg className="close-sharing-menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 45.27L466.73 0 256 210.73 45.27 0 0 45.27 210.73 256 0 466.73 45.27 512 256 301.27 466.73 512 512 466.73 301.27 256z"></path></svg>
                    </> :
                    <>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 362.56 511.99"><path d="M345.07 194.56a17.48 17.48 0 0 0-17.48 17.49V477H35V212a17.5 17.5 0 1 0-35 0v265a35 35 0 0 0 35 35h292.59a35 35 0 0 0 35-35V212a17.49 17.49 0 0 0-17.52-17.44zM84.16 125.64a17.48 17.48 0 0 0 24.69-1.34l54.85-61.14v313.32a17.5 17.5 0 1 0 35 0V63.17l54.85 61.13a17.49 17.49 0 0 0 26-23.36L194.2 5.81a17.48 17.48 0 0 0-26 0l-85.38 95.13a17.49 17.49 0 0 0 1.34 24.7z"/></svg>
                    </>}
                </a>
            </div>
        </div>
    )
}

export default Toolbar