import React from 'react'
import {Link} from "react-router-dom"

function LetterBlock({closeMenu, letter, authors}) {

    
    return (
        <ul id={`autor-${letter}`}className="submenu__children submenu__children--letters">
            <div className="submenu__children--container">
                <h4 className="has-font__sectra-grotesk has-black-color">{letter}</h4>
                {authors.map(author => <li key={author.slug} id={`autor-${author.slug}`} className="submenu__child menu-item">
                    <Link onClick={()=> closeMenu()} to={author.uri}><span>{author.name}</span></Link>
                </li>)}
                
            </div>
        </ul>
    )
}

export default LetterBlock
