import React, { useState, useRef } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import MagazinPreview from "./MagazinePreview";
import Footer from "../../layout/Footer";
import Loading from "../../utility/Loading";
import Error from "../Error";
import { Helmet } from "react-helmet-async";
import HTML2React from "html2react";
import { useSelector } from "react-redux";

function Magazines() {
  const pageInfo = useRef(null);
  const [magazines, setMagazines] = useState([]);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isSubscribed = useSelector(
    (state) => state.userData.subscriptionAccessStatus
  );
  const { data, loading, error, refetch } = useQuery(GQL_Query.GET_MAGAZINES, {
    variables: { limit: 12 },
    onCompleted(data) {
      setMagazines(data.magazines.nodes);
      pageInfo.current = data.magazines.pageInfo;
      if (navigator.onLine) refetch({ fetchPolicy: "network-only" });
    },
  });

  const [LoadMagazines, { loading: loadingMore, refetch: refetchMore }] =
    useLazyQuery(GQL_Query.GET_MAGAZINES, {
      onError(err) {
        // Error handling here
        //console.log(err.message);
      },
      onCompleted(data) {
        setMagazines(magazines.concat(data.magazines.nodes));
        pageInfo.current = data.magazines.pageInfo;
        if (navigator.onLine) refetchMore({ fetchPolicy: "network-only" });
      },
    });

  const LoadMore = () => {
    LoadMagazines({
      variables: { limit: 12, after: pageInfo.current.endCursor },
    });
  };

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <>
      <Helmet>
        {HTML2React(data.seo.contentTypes.magazin.archive.fullHead)}
      </Helmet>
      <main className="main grid-container full border-top">
        {data.acfOptionsFrontend.nextIssueDate.nextIssueDate && (
            <div className="grid-x rep-padding-left-100 rep-padding-right-100">
                <p className="cell no-margin__bottom text-center archive-magazin__next-issue">
                    Die nächste Ausgabe erscheint am <span className="has-white-space-nowrap">{data.acfOptionsFrontend.nextIssueDate.nextIssueDate}.</span>
                </p>
            </div>
        )}
        <div className="grid-x archive-magazin" style={{"overflow" : "hidden"}}>
          {magazines.map((magazine) => (
            <MagazinPreview
              key={magazine.id}
              magazine={magazine}
              isLoggedIn={isLoggedIn}
              isSubscribed={isSubscribed}
            />
          ))}

          {pageInfo.current?.hasNextPage && (
            <div className="cell text-center rep-margin-top-100">
              <button
                className={`has-red-background-color ${
                  loadingMore && `loading-more`
                }`}
                onClick={LoadMore}
              >
                Mehr laden
              </button>
            </div>
          )}
        </div>
      </main>

      <Footer />
    </>
  );
}

export default Magazines;
