import React, {useState} from 'react'
//import "../../../../../css/Toolbox.css"
import Tools from "./Tools"
import FontSizeMenu from './FontSizeMenu'
import SharingMenu from './SharingMenu'

function Toolbar({reportage, hasEmailPass, emailPassToken}) {
    const [showSharingMenu, setShowSharingMenu] = useState(false)
    const [showFontSizeMenu, setShowFontSizeMenu] = useState(false)

    const toggleSharingMenu = () => {
        setShowFontSizeMenu(false)
        setShowSharingMenu(!showSharingMenu)
    }

    const toggleFontSizeMenu = () => {
        setShowSharingMenu(false)
        setShowFontSizeMenu(!showFontSizeMenu)
    }
    

    return (
        <div className="toolbox" style={{ background: reportage.reportageFooterColor }}>
          {showSharingMenu && <SharingMenu title={reportage.title} toggleSharingMenu={toggleSharingMenu} slug={reportage.slug} emailPassToken={emailPassToken}/>}
          {showFontSizeMenu && <FontSizeMenu toggleFontSizeMenu={toggleFontSizeMenu}/> }
        <Tools reportage={reportage} toggleSharingMenu={toggleSharingMenu} toggleFontSizeMenu={toggleFontSizeMenu} hasEmailPass={hasEmailPass} emailPassToken={emailPassToken}/>
    </div>
    )
}

export default Toolbar
