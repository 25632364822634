import React from 'react'
import MagazineMenu from "./MagazineMenu"
import LayerMenu from "./LayerMenu"
import SeriesMenu from "./SeriesMenu"
import LinkedMenuItem from "./LinkedMenuItem"
import MultiLinkMenu from "./MultiLinkMenu"
import {useSelector } from "react-redux"


function MenuContent({handleMenuSelection, closeMenu}) {
    const sectionsMenu = useSelector(state => state.menu.sections)
    const pagesMenu = useSelector(state => state.menu.pages)
    const regionsMenu = useSelector(state => state.menu.regions)
    const seriesMenu = useSelector(state => state.menu.series)
    const topicsMenu = useSelector(state => state.menu.topics)

    return (
        <>
            <MagazineMenu handleMenuSelection={handleMenuSelection} closeMenu={closeMenu}/>
            <LayerMenu handleMenuSelection={handleMenuSelection} title="Themen" menu={topicsMenu}/>
            <SeriesMenu handleMenuSelection={handleMenuSelection} closeMenu={closeMenu} series={seriesMenu}/>
            <LayerMenu handleMenuSelection={handleMenuSelection} title="Regionen" menu={regionsMenu}/>
            <div className="submenu">                
                <ul id="sections-menu">
                    {sectionsMenu.map(menuItem => menuItem.childItems.nodes.length > 0 ? <MultiLinkMenu key={menuItem.id} title={menuItem.label} submenu={menuItem.childItems.nodes} closeMenu={closeMenu} /> : <LinkedMenuItem key={menuItem.id} item={menuItem} closeMenu={closeMenu}/>)}
                </ul>
            </div>
            <div className="submenu">
                <ul id="pages-menu">
                    {pagesMenu.map(menuItem => menuItem.childItems.nodes.length > 0 ? <MultiLinkMenu key={menuItem.id} title={menuItem.label} submenu={menuItem.childItems.nodes} closeMenu={closeMenu}/> : <LinkedMenuItem key={menuItem.id} item={menuItem} closeMenu={closeMenu}/>)}
                </ul>
            </div>                   
        </>
    )
}

export default MenuContent
