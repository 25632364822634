import React from "react";
import { Link } from "react-router-dom";

function SeriesMenu({ handleMenuSelection, closeMenu, series }) {
  return (
    <div className="submenu">
      <a className="submenu__parent menu-item">
        <span>Serien</span>
      </a>
      <ul className="submenu__children submenu__children--primary">
        {series.map(
          (serie) =>
            serie.count > 0 && (
              <li key={serie.id} className="submenu__child menu-item">
                <Link to={`/serie/${serie.slug}`} onClick={() => closeMenu()}>
                  <span>
                    {serie.name} <small>{serie.count}</small>
                  </span>
                </Link>
              </li>
            )
        )}
        <li className="submenu__child menu-item">
          <span
            tabIndex="0"
            role="button"
            onClick={() => handleMenuSelection("seriesArchiveMenu")}
          >
            Serienarchiv
          </span>
        </li>
      </ul>
    </div>
  );
}

export default SeriesMenu;
