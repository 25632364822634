import React, {useEffect, useState} from "react";
import shopClient from "../../../services/apollo/shopClient";
import GQL_Query from "../../../services/graphql/queries";
import {useLazyQuery, useQuery} from "@apollo/client";

function MailchimpSubscription({ user }) {

    const [mailchimpstatus,setMailchimpstatus] = useState('checking');
    const [mailchimpurl, setMailchimpurl] = useState('https://reportagen.us17.list-manage.com/subscribe?u=7d7daeea7993cdf5f5bdfeb3a&id=f8f41720ad');
    const [loadMailchimpData] = useLazyQuery(GQL_Query.GET_USER_MAILCHIMP_STATUS, {
        variables: { email: user.email},
        fetchPolicy: "network-only",
        client: shopClient,
        onError(err) {
            // Error handling here
            // console.log("onError:", err.message);
        },
        onCompleted(data) {
            const mailchimpData = JSON.parse(data.getMailchimpStatusByEmail);
            setMailchimpstatus(mailchimpData.status);
            // save mailchimp subscription status to local storage
            localStorage.setItem('nl_subscription', JSON.stringify(user.subscriptionAccessStatus));
            if (mailchimpData.status == 'subscribed') {
                let preferenceUrl = mailchimpData.subscribeUrlLong.replace('subscribe','profile') + '&e=' + mailchimpData.uniqueEmailId;
                setMailchimpurl(preferenceUrl);
            }
        },
    });

    useEffect(() => {
        if(navigator.onLine) {
            loadMailchimpData();
        } else {
            setMailchimpstatus('offline');
        }
    }, []);

    return (
        <div className="cell">
            <p className="has-font__sectra-grotesk has-dark-gray-color no-margin__bottom">Newsletter-Einstellungen:</p>
            <p>
            {(mailchimpstatus == 'checking') ?
                <><span className="has-dark-gray-color">Einstellungen laden …</span></>
                : (mailchimpstatus == 'subscribed') ? <>Angemeldet | <a href={`${mailchimpurl}`} target="_blank">Einstellungen</a></>
                : (mailchimpstatus == 'offline') ? <>Unbekannt – Gerät ist offline.</>
                : <>Nicht angemeldet | <a href={`${mailchimpurl}`} target="_blank">Newsletter abonnieren</a></>
            }
            </p>
        </div>
    );
}

export default MailchimpSubscription;