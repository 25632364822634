// GraphQL API URL
//let contentUrl = "https://dev.content.reportagen.com/graphql";
// contentUrl="https://reportagen.graphcdn.app/";
let contentUrl= "https://content.reportagen.com/graphql";
let shopUrl = "https://shop.reportagen.com/graphql";

const Config = {
  gqlUrl: contentUrl,
  gqlShop: shopUrl,
};

export default Config;
