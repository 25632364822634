import React, { useState } from "react";
import GQL_Mutation from "../../../../services/graphql/mutations";
import { useMutation } from "@apollo/client";
import shopClient from "../../../../services/apollo/shopClient";

function EmailPass({ title, id, slug }) {
  const [agreement, setAgreement] = useState(false);
  const [email, setEmail] = useState("");
  const [requestSucceeded, setRequestSucceeded] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const [requestEmailPass, { loading }] = useMutation(
    GQL_Mutation.REQUEST_EMAIL_PASS,
    {
      variables: {
        email: email,
        title: title,
        token: id,
        url: "https://reportagen.com/reportage/" + slug,
      },
      client: shopClient,
      onError(err) {
        // Error handling here
        //console.log(err.message);
      },
      onCompleted(data) {
        //console.log(data)
        if (data.requestEmailpass.isValid) {
          setRequestSucceeded(true);
        } else {
          //console.log(data.requestEmailpass.validationMessages)
          const error = JSON.parse(data.requestEmailpass.validationMessages);
          error[3]
            ? setErrorMsg(error[3])
            : setErrorMsg(
                "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal."
              );
        }
      },
    }
  );

  const handleInputChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    name === "emailPassAgreement" ? setAgreement(value) : setEmail(value);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const prepareSend = (e) => {
    e.preventDefault();
    const isEmail = validateEmail(email);
    isEmail
      ? requestEmailPass()
      : setErrorMsg("Bitte geben Sie eine gültige E-Mail-Adresse ein.");
  };

  if (requestSucceeded)
    return (
      <div className="has-yellow-background-color rep-padding-all-100 rep-margin-bottom-200">
        <h3 className="has-font__sectra-grotesk no-margin__top">
          Ihr Einmal-Link zum Lesen ist unterwegs!
        </h3>
        <p className="has-font__sectra-grotesk">
          Sie haben soeben eine E-Mail mit dem einmaligen Zugangs-Link für diese
          Reportage erhalten.
        </p>
        <p className="has-font__sectra-grotesk">
          Klicken Sie einfach den Link in Ihrer E-Mail, um den Text in voller
          Länge zu lesen.
        </p>
        <p className="has-font__sectra-grotesk">
          Wir wünschen anregende Lektüre!
        </p>
        <p className="has-font__sectra-grotesk no-margin__bottom">
          Die <em>Reportagen</em>-Redaktion!
        </p>
      </div>
    );

  return (
    <div className="emailpass">
      <h3 className="has-font__sectra-grotesk">
        Sie möchten nur diese Reportage probelesen?
      </h3>
      <p className="has-font__sectra-grotesk">
        So können Sie <em>Reportagen</em> sofort kennenlernen: Geben Sie unten
        eine gültige E-Mail-Adresse ein. An diese Adresse erhalten Sie danach
        einen Zugangslink zu dieser Reportage.
      </p>
      {errorMsg && (
        <div className="cell">
          <div className="has-yellow-background-color has-small-font-size has-font__sectra-grotesk rep-padding-all-50">
            {errorMsg}
          </div>
        </div>
      )}
      <div className="form__group has-font__sectra-grotesk">
        <input
          type="text"
          placeholder="Ihre E-Mail"
          className="form__field"
          id="email"
          name="emailAdress"
          onChange={handleInputChange}
          value={email}
        />
        <label className="form__label" htmlFor="username">
          Ihre E-Mail
        </label>
      </div>
      <div className="form__group">
        <label
          className="form__label--for-radio"
          style={{ position: "relative" }}
        >
          <input
            type="checkbox"
            id="email-pass-agreement"
            name="emailPassAgreement"
            onChange={handleInputChange}
            value={agreement}
          />
          <span
            className="has-font__sectra-grotesk has-black-color"
            htmlFor="email-pass-agreement"
          >
            Mit der Anforderung des einmaligen Zugangslinks zu dieser Reportage
            per E-Mail erkläre ich mich damit einverstanden, dass die von mir
            angegebenen personenbezogenen Daten durch die Puntas Reportagen AG
            verarbeitet und genutzt werden dürfen. Die Nutzung umfasst die
            Ansprache per E-Mail zur Kontaktaufnahme und zur Aufnahme in den
            Verteiler für einen E-Mail-Newsletter, in dem das Magazin{" "}
            <em>Reportagen</em> regelmässig über Aktionen und Veranstaltungen
            informiert. Ich kann die von mir erteilte Einwilligung zur Nutzung
            meiner Daten jederzeit mit Wirkung für die Zukunft durch Mitteilung
            an support@reportagen.com widerrufen. Ausserdem kann ich mich von
            den E-Mail-Newslettern jeweils am Ende des E-Mails mit einem Klick
            abmelden.
          </span>
        </label>
      </div>
      <button
        disabled={!agreement || !email}
        onClick={prepareSend}
        className={`${loading && `loading`}`}
      >
        Zugangslink anfordern
      </button>
    </div>
  );
}

export default EmailPass;
