import React from "react";
import Footer from "../../layout/Footer";
import Loading from "../../utility/Loading";
import { useQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import HTML2React from "html2react";
import Error from "../Error";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import UserMenu from "../../layout/UserMenu";

function Feedback({ notLoggedIn, notSubscribed, noBookmarks }) {
  const seoImage = useSelector((state) => state.seoImage);

  const { loading, error, data, refetch } = useQuery(
    GQL_Query.GET_BOOKMARKS_FEEDBACK,
    {
      onError(err) {
        // Error handling here
        //console.log(err.message);
      },
      onCompleted(data) {
        //console.log(data);
        if (navigator.onLine) refetch({ fetchPolicy: "network-only" });
      },
    }
  );

  const ReplaceLinks = (props) => {
    if (props.href?.includes("http")) {
      return <a {...props} target="_blank" rel="noopener noreferrer" />;
    } else {
      return <Link {...props} to={props.href} />;
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <>
      <Helmet>
        <title>Lesezeichen</title>
        <meta name="description" content="Gespeicherte Reportagen." />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Gespeicherte Reportagen" />
        <meta property="og:description" content="Gespeicherte Reportagen." />
        <meta property="og:site_name" content="Reportagen" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/reportagen"
        />
        <meta property="og:image" content={seoImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@reportagench" />
      </Helmet>
      <div className="bookmarks">
        <UserMenu />
        <div className="grid-container">
          <div className="grid-x grid-margin-x grid-padding-x">
            <div className="cell">
              <h3 className="has-font__sectra-grotesk-bold">Lesezeichen</h3>
            </div>
            <div className="cell">
              {notLoggedIn &&
                data.acfOptionsFrontend.staticBlocks.blockBookmarksLoggedout &&
                HTML2React(
                  data.acfOptionsFrontend.staticBlocks.blockBookmarksLoggedout
                    .content,
                  { a: ReplaceLinks }
                )}
              {notSubscribed &&
                data.acfOptionsFrontend.staticBlocks
                  .blockBookmarksNoSubscription &&
                HTML2React(
                  data.acfOptionsFrontend.staticBlocks
                    .blockBookmarksNoSubscription.content,
                  { a: ReplaceLinks }
                )}
              {noBookmarks &&
                data.acfOptionsFrontend.staticBlocks.blockBookmarksEmpty &&
                HTML2React(
                  data.acfOptionsFrontend.staticBlocks.blockBookmarksEmpty
                    .content
                )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Feedback;
