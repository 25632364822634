import React, { useState, useRef } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import Footer from "../../layout/Footer"
import Error from "../Error";
import Loading from "../../utility/Loading"
import HTML2React from "html2react"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet-async";

function Topic(props) {
  const { slug } = props.match.params;
  const pageInfo = useRef(null);
  const [reportages, setReportages] = useState([])

  const { loading, error, data, refetch } = useQuery(GQL_Query.GET_TOPIC_BY_SLUG, {
    variables: { slug: slug, limit: 10 },
    onCompleted(data) {
      //console.log(data);
      setReportages(data.rubrik.contentNodes.nodes)
      pageInfo.current = data.rubrik.contentNodes.pageInfo;
      if(navigator.onLine) refetch({fetchPolicy: "network-only"})  
    },
    onError(err) {
      //console.log(err);
    },
  });

  const [LoadReportages, {loading: loadingMore, refetch: refetchMore}] = useLazyQuery(GQL_Query.GET_TOPIC_BY_SLUG, {
    onError(err) {
      // Error handling here
      //console.log(err.message);
    },
    onCompleted(data) {       
        setReportages(reportages.concat(data.rubrik.contentNodes.nodes));
        pageInfo.current = data.rubrik.contentNodes.pageInfo;
        if(navigator.onLine) refetchMore({fetchPolicy: "network-only"})  
    },
  });

  const LoadMore = () => {
    //console.log("load more..");    
    LoadReportages({
        variables: { slug: slug, limit: 10, after: pageInfo.current.endCursor },
      });
  };

 

  if (loading) return <Loading/>;
  if (error) return <Error/>;
  if (!data.rubrik) return <Error />;

    return (
        <>
        <Helmet>{HTML2React(data.rubrik.seo.fullHead)}</Helmet>
        <div className="border-top"></div>
        <div className="grid-container">
            <main className="main grid-x grid-padding-x grid-margin-x archive-reportagen" role="main">
                <header className="archive-reportagen__header cell">
                <p className="archive-reportagen__header--meta has-font__sectra-grotesk has-dark-gray-color">
                    <small>Ergebnisse für</small>
                </p> 
                <h1 className="h3 has-font__sectra-grotesk-bold cell">
                    {data.rubrik.name}
                </h1>
                </header>
                <p className="cell has-font__sectra-grotesk has-small-font-size has-red-color archive-reportagen__counter no-margin__bottom">
                    {data.rubrik.taxReportageCount?.reportagenCount > 0 &&  <span>
                        Reportagen: {data.rubrik.taxReportageCount?.reportagenCount}
                    </span>}
                
                    {data.rubrik.taxReportageCount?.articleCount > 0 && <span>
                        Artikel: {data.rubrik.taxReportagenCount?.articleCount}
                    </span>}             
                </p>
                <section className="archive-reportagen__content grid-container" itemProp="text">
                    <div className="grid-x grid-padding-x grid-margin-x">                    
                    {reportages.map(reportage => <Link key={reportage.id} to={reportage.uri} className={`cell has-text-decoration-none search-result  ${reportage?.searchResultClass && reportage.searchResultClass}`}>
                                {HTML2React(reportage.searchResultMarkup)}
                        </Link>)}
                        {pageInfo.current?.hasNextPage && (
                        <div className="cell">                       
                            <button className={`has-red-background-color ${loadingMore && `loading-more`}`} onClick={LoadMore}>Mehr laden</button>
                        </div>
                        )}
                    </div>
                </section>
            </main>
        </div>
        <Footer/>
        </>
    )
}

export default Topic
