import React, {useEffect, useRef} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { SetCurrentPath, SetPreviousPath } from "../../../services/redux/actions";

// Content
import Page from "../../content/Page";
import Home from "../../content/Home";
import Magazines from "../../content/Magazines";
import Magazine from "../../content/Magazine";
import News from "../../content/News";
import NewsArchive from "../../content/NewsArchive";
import Authors from "../../content/Authors";
import Author from "../../content/Author";
import Reportage from "../../content/Reportage";
import Error from "../../content/Error";
import Account from "../../content/Account";
import Login from "../../content/Login";
import Bookmarks from "../../content/Bookmarks";
import Illustrators from "../../content/Illustrators";
import Illustrator from "../../content/Illustrator";
import Serie from "../../content/Serie";
import Series from "../../content/Series"
import Event from "../../content/Event";
import Events from "../../content/Events";
import EventsArchive from "../../content/EventsArchive";
import Region from "../../content/Region";
import Regions from "../../content/Regions"
import Topic from "../../content/Topic"
import Topics from "../../content/Topics"
import Special from "../../content/Special"
import Specials from "../../content/Specials"
import FAQ from "../../content/FAQ";

// Custom Routes
//import ProtectedRoute from "./ProtectedRoute";
//import PublicRoute from "./PublicRoute";



function Content() {
  
  const currentPath = useRef(null);
  const previousPath = useRef(null);
  const location = useLocation(); 
  const dispatch = useDispatch();

  
  useEffect(() => {
    if (location.pathname !== currentPath.current) {        
        dispatch(SetPreviousPath(currentPath.current));
        dispatch(SetCurrentPath(location.pathname));
        previousPath.current = currentPath.current;
        currentPath.current = location.pathname;   
    }
  }, [location.pathname]);

  //console.log("-- Content/index.js --")
  

  return (
    <div className="content">
      <Switch>
        <Route exact path="/bookmarks" component={Bookmarks} />
        <Route exact path="/news" component={NewsArchive} />
        <Route exact path="/news/:slug" component={News} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/event">
          <Redirect to="/events"/>
        </Route>
        <Route exact path="/event-archiv" component={EventsArchive} />
        <Route exact path="/event/:slug" component={Event} />
        <Route exact path="/magazin" component={Magazines} />
        <Route exact path="/magazin/:slug" component={Magazine} />
        <Route exact path="/reportage">
          <Redirect to="/"/>
        </Route>
        <Route exact path="/reportage/:slug" component={Reportage} />
        <Route exact path="/illustratorin" component={Illustrators} />
        <Route exact path="/illustratorin/:slug" component={Illustrator} />
        <Route exact path="/autorin" component={Authors} />
        <Route exact path="/autorin/:slug" component={Author} />
        <Route exact path="/serie/:slug" component={Serie} />
        <Route exact path="/serie">
          <Redirect to="/serien"/>
        </Route>
        <Route exact path="/serien" component={Series} />
        <Route exact path="/region/:slug" component={Region} />
        <Route exact path="/region">
          <Redirect to="/regionen"/>
        </Route>
        <Route exact path="/regionen" component={Regions} />
        <Route exact path="/thema/:slug" component={Topic} />
        <Route exact path="/thema">
          <Redirect to="/themen"/>
        </Route>
        <Route exact path="/themen" component={Topics} />
        <Route exact path="/special/:slug" component={Special} />
        <Route exact path="/special">
          <Redirect to="/specials"/>
        </Route>
        <Route exact path="/specials" component={Specials} />
        <Route exact path="/konto" component={Account} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/:slug" component={Page} />
        <Route exact path="/" component={Home} />
        <Route component={Error} />
      </Switch>
    </div>
  );
}

export default Content;
