import React from "react";
import HTML2React from "html2react";
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../../css/LazyLoadImage.css";

function NewsArticle({ news }) {
  const date = new Date(news.date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date_f = date.toLocaleDateString("de-DE", options);

  const ReplaceLinks = (props) => {
    if(props.href?.includes("http")) {
        return <a {...props} target="_blank" rel="noopener noreferrer"/>
    }
    else {
      return <Link {...props} to={props.href} />
    }
  }

    const Image = (image) => {
        return <LazyLoadImage
            alt={image.alt}
            src={image.src}
            srcSet={image.srcSet}
            width={image.width}
            height={image.height}
            style={image.style}
            effect="opacity"
        />;
    };

  return (
    <article itemScope itemType="https://schema.org/BlogPosting">
      <section className="article-header has-blue-color">
        <p className="date has-medium-font-weight">{date_f}</p>
        <Link to={`/news/${news.slug}`} className="has-text-decoration-none" title={news.title}>
          <h2
            className="entry-title single-title has-font__sectra-grotesk has-medium-font-weight"
            itemProp="name"
          >
            {news.title}
          </h2>
        </Link>
        <p className="lead has-medium-font-weight">{news.excerptRaw}</p>
      </section>

      <section className="entry-content" itemProp="text">
        {news.featuredImage && (
          <Link to={`/news/${news.slug}`} className="has-text-decoration-none" title={news.title}>
            <figure className="featured-image">
                <LazyLoadImage
                    width={news.featuredImage?.node.mediaDetails.width}
                    height={news.featuredImage?.node.mediaDetails.height}
                    src={news.featuredImage?.node.mediaItemUrl}
                    className="attachment-full size-full wp-post-image"
                    alt={news.featuredImage?.node.altText}
                    srcSet={news.featuredImage?.node.srcSet}
                    sizes={news.featuredImage?.node.sizes}
                    wrapperProps={{style: {height: "100%", width: "100%", display: "flex" }}}
                    effect="opacity"
                />
                {news.featuredImage.node.caption &&
                    <figcaption>
                        {HTML2React(news.featuredImage.node.caption, { a: ReplaceLinks })}
                    </figcaption>
                }
            </figure>
          </Link>
        )}

        {HTML2React(news.content, {
            a: ReplaceLinks,
            img: Image,
        })}
      </section>
    </article>
  );
}

export default NewsArticle;
