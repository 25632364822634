import gql from "graphql-tag";

const mutations = {
  LOGIN_USER: gql`
    mutation LoginUser($user: String!, $password: String!) {
      login(input: { username: $user, password: $password }) {
        authToken
        user {
          email
          firstName
          lastName
          username
          jwtAuthToken
          jwtRefreshToken
          subscriptionAccessStatus
          subscriptions {
            expires
            plan_id
            status
            title
          }
          bookmarks {
            bookmarkClass
            bookmarkMarkup
            bookmarkPostId
            bookmarkSlug
            bookmarkTitle
          }
        }
      }
    }
  `,
  REFRESH_TOKEN: gql`
    mutation RefreshAuthToken($token: String!) {
      refreshJwtAuthToken(input: { jwtRefreshToken: $token }) {
        authToken
      }
    }
  `,
  SAVE_BOOKMARK: gql`
    mutation SAVE_BOOKMARK($user: String!, $id: Int) {
      saveBookmark(input: { jwtAuthToken: $user, postID: $id }) {
        message
        validation
      }
    }
  `,
  DELETE_BOOKMARK: gql`
    mutation DELETE_BOOKMARK($user: String!, $id: Int) {
      deleteBookmark(input: { jwtAuthToken: $user, postID: $id }) {
        message
        validation
      }
    }
  `,
  REQUEST_EMAIL_PASS: gql`
    mutation REQUEST_EMAIL_PASS(
      $email: String!
      $token: String!
      $url: String!
      $title: String!
    ) {
      requestEmailpass(
        input: {
          passEmail: $email
          passReportageUrl: $url
          passToken: $token
          passReportageTitle: $title
        }
      ) {
        clientMutationId
        confirmationMessage
        isValid
        validationMessages
      }
    }
  `,
};

export default mutations;
