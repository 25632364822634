import React from "react";
import {Link} from "react-router-dom";
import HTML2React from "html2react"

function Welcome({ msg }) {
 
  const ReplaceLinks = (props) => {
    if(props.href?.includes("http")) {
        return <a {...props} target="_blank" rel="noopener noreferrer"/>
    }
    else {
      return <Link {...props} to={props.href} />
    }
  }
  
  

  return (
    <div className="cell">      
      {msg && HTML2React(msg, { a: ReplaceLinks, })}
    </div>
  );
}

export default Welcome;
