import { combineReducers } from "redux";
import isLoggedInReducer from "./isLoggedIn";
import userDataReducer from "./userData";
import bookmarksReducer from "./bookmarks";
import isMenuOpenReducer from "./isMenuOpen";
import latestIssueReducer from "./latestIssue"
import loginCheckDoneReducer from "./loginCheckDone";
import fontSizeReducer from "./fontSize";
import snackbarReducer from "./snackbar";
import menuReducer from "./menu"
import loadingContentDoneReducer from "./loadingContentDone";
import seoImageReducer from "./seoImage";
import pathReducer from "./path";
import searchInputReducer from "./searchInput";
import systemPagesReducer from "./systemPages";

const allReducers = combineReducers({
  isLoggedIn: isLoggedInReducer,
  userData: userDataReducer,
  bookmarks: bookmarksReducer,
  isMenuOpen: isMenuOpenReducer,
  latestIssue: latestIssueReducer,
  loginCheckDone: loginCheckDoneReducer,
  fontSize: fontSizeReducer,
  snackbar: snackbarReducer,
  menu: menuReducer,
  loadingContentDone: loadingContentDoneReducer,
  seoImage: seoImageReducer,
  path: pathReducer,
  searchInput: searchInputReducer,
  systemPages: systemPagesReducer
});

export default allReducers;
