import React from "react";
import { Helmet } from "react-helmet-async";

function ModifiedHead() {
    return (
        <div>
            <Helmet>
                <title>Vergangene Events – Reportagen</title>
                <meta property="og:title" content="Vergangene Events - Reportagen" data-rh="true" />
            </Helmet>
        </div>
    )
}

export default ModifiedHead;