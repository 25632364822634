import React from "react";
import { Link } from "react-router-dom";
import HTML2React from "html2react";

function RelatedReportage({ reportage }) {
  const url = `/reportage/${reportage.slug}`;
  
  return (
    <Link
      to={url}
      className={`cell large-auto align-self-middle text-center related-reportage ${reportage?.searchResultClass && reportage.searchResultClass}`}
    >
     {HTML2React(reportage.markup)}
    </Link>
  );
}

export default RelatedReportage;
