import React from 'react'
import Footer from '../../layout/Footer'
import HTML2React from "html2react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import UserMenu from '../../layout/UserMenu';
import {useSelector} from "react-redux";

function Content({data, isLoggedIn}) {
    const User = useSelector(state => state.userData);
    const ReplaceLinks = (props) => {
        if (props.href?.includes("http") || props.href?.includes("mailto")) {
          return <a {...props} target="_blank" rel="noopener noreferrer" />;
        } else {
          return <Link {...props} to={props.href} />;
        }
      };
      
  return (
    <>
    <Helmet>{HTML2React(data.page.seo.fullHead)}</Helmet>
    {isLoggedIn && <UserMenu/>}
    <div className="grid-container">
      <div className="inner-content grid-x grid-margin-x grid-padding-x">
        <main className="main small-12 large-12 medium-12 cell" role="main">
          <article
            className={`page ${data.page.pageOptions.bodyFont}`}
            itemScope=""
            itemType="http://schema.org/WebPage"
          >
            {!isLoggedIn ?
              <header
                className={`article-header ${data.page.pageOptions.pageHeaderWidth} ${data.page.pageOptions.pageHeaderAlignment}`}
              >
                <h1
                  className={`page-title has-bold-font-weight ${data.page.pageOptions.bodyFont}  ${data.page.pageOptions.pageHeaderFontSize}`}
                >
                  {data.page.title}
                </h1>
                <p className="lead has-font__gt-sectra__medium">{HTML2React(data.page.excerptRaw)}</p>
              </header> :
                <div className="cell faq-account">
                  <h3 className="has-font__sectra-grotesk-medium">{data.page.title}</h3>
                  <p className="has-font__sectra-grotesk has-small-font-size has-dark-gray-color">{HTML2React(data.page.excerptRaw)}</p>
                </div>
            }
            <section className="entry-content" itemProp="text">
              {HTML2React(data.page.content, { a: ReplaceLinks })}
              <p className="has-font__sectra-grotesk border-top rep-padding-top-100 rep-margin-bottom-50"><span className="has-medium-font-weight">Sie benötigen weitere Hilfe?</span> <br />Unser Support hilft Ihnen gerne weiter.</p>
              {isLoggedIn ?
                  <a className="button"
                     href={`https://shop.reportagen.com/support/?email=${User.email}&fname=${User.firstName}&lname=${User.lastName}`}
                     target="_blank">Support kontaktieren
                  </a> :
                  <a className="button"
                     href="https://shop.reportagen.com/support/"
                     target="_blank">Support kontaktieren
                  </a>
              }
              <div className="rep-margin-bottom-100"></div>
            </section>
          </article>
        </main>
      </div>
    </div>
    <Footer />
  </>
  )
}

export default Content