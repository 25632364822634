import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import Loading from "../../utility/Loading";
import Error from "../Error";
import { useQuery, useLazyQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import Content from './content';
import {Link} from "react-router-dom";

function FAQ() {
    const isLoggedIn = useSelector((state) => state.isLoggedIn);

    const {  data, error, loading, refetch  } = useQuery(
        GQL_Query.GET_FAQ_PAGE,
        {
            onCompleted(data) {
                // console.log(data);
                if (navigator.onLine) refetch({ fetchPolicy: "network-only" });
            },
        }
      );

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return <Loading />;

    return <Content data={data} isLoggedIn={isLoggedIn}/>
}

export default FAQ