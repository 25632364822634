const searchInputReducer = (state = null, action) => {
    switch(action.type) {
        case "SET_SEARCH_INPUT":
            return action.payload 
        case "DEL_SEARCH_INPUT":
            return null     
        default:
            return state
    }
}

export default searchInputReducer;