const userDataReducer = (state = {}, action) => {
    switch(action.type) {
        case "SET_USER_DATA":
            return action.payload
        case "DEL_USER_DATA":
            return {}     
        default:
            return state
    }
}

export default userDataReducer;