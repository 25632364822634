import React from "react";

function SearchInput({handleSearch, searchTerm, resetSearch, status, resultCount}) {
 
  return (
    <div className="form__group menu-search">
      <input autoComplete="off" type="text" id="search" className="form__field" value={searchTerm} placeholder="Suche" onChange={handleSearch}/>
      <label htmlFor="search" className="form__label">Suche</label>
      {searchTerm && <span className="search-reset" onClick={() => resetSearch()}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 45.27L466.73 0 256 210.73 45.27 0 0 45.27 210.73 256 0 466.73 45.27 512 256 301.27 466.73 512 512 466.73 301.27 256z"></path></svg></span>}
      {searchTerm && searchTerm.length < 4 && <span className="search-feedback search-feedback__minimum-length">Ihr Suchbegriff muss mindestens 4 Zeichen lang sein.</span>}
      {searchTerm.length > 3 && status === "loading" && <span className="search-feedback search-feedback__searching">Suche nach <strong>{searchTerm}</strong> ...</span>}
      {searchTerm.length > 3 && status === "noresults" && <span className="search-feedback search-feedback__no-result">Zu <strong>{searchTerm}</strong> konnte nichts gefunden werden.</span>}
      {searchTerm.length > 3 && status === "finished" && <span className="search-feedback search-feedback__result has-red-color">{resultCount} Suchergebnisse für <strong>{searchTerm}</strong></span>}
    </div>    
  );
}

export default SearchInput;
