import React, { useState, useRef } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import Error from "../Error";
import { Link } from "react-router-dom";
import HTML2React from "html2react";
import Footer from "../../layout/Footer"
import Loading from "../../utility/Loading"
import { Helmet } from "react-helmet-async";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../../css/LazyLoadImage.css";

function Illustrator(props) {
  const { slug } = props.match.params;  
  const [illustrations, setIllustrations] = useState([]);  
  const pageNr = useRef(null);  
  const maxPages = useRef(null)
  const resultCount = useRef(null)

  const { loading, error, data, refetch } = useQuery(
    GQL_Query.GET_ILLUSTRATOR_BY_SLUG,
    {
      variables: { slugID: slug},
      onError(err) {
        // Error handling here
        //console.log(err.message);
      },
      onCompleted(data) {        
        //console.log(data)
        // setIllustrations(data.mediaItems.nodes)
        pageNr.current = 0;
        maxPages.current = data.illustrator.imagesBy.maxNumPages;
        resultCount.current = data.illustrator.imagesBy.totalImages;
        const images = JSON.parse(data.illustrator.imagesBy.imagesJson)
        setIllustrations(images[0])
        if(navigator.onLine) refetch({fetchPolicy: "network-only"})
      },
    }
  );

  const LoadMore = () => {
    const images = JSON.parse(data.illustrator.imagesBy.imagesJson)
    setIllustrations(illustrations.concat(images[pageNr.current + 1]))
    pageNr.current = pageNr.current + 1
  }

 



  const ReplaceLinks = (props) => {
    if(props.href?.includes("http")) {
        return <a {...props} target="_blank" rel="noopener noreferrer"/>
    }
    else {
      return <Link {...props} to={props.href} />
    }
  }

  if (loading) return <Loading/>
  if (error) return <Error/>
  if (!data.illustrator) return <Error/> 

  

  return (
    <>
      <Helmet>{HTML2React(data.illustrator.seo.fullHead)}</Helmet>
      <div className="border-top"></div>
        <main className="main" role="main" style={{"overflow" : "hidden"}}>
          <article className="grid-container" itemProp="author" itemScope itemType="http://schema.org/Person">
            <div className="grid-x grid-padding-x grid-margin-x archive-reportagen">
              <header className="archive-reportagen__header cell">
                  <p className="archive-reportagen__header--meta has-font__sectra-grotesk has-dark-gray-color">
                      <small>Ergebnisse für</small>
                  </p>
                  <h1 className="h3 has-font__sectra-grotesk-bold cell" itemProp="name">
                      {data.illustrator.title}
                  </h1>
              </header>
              <p className="cell has-font__sectra-grotesk has-small-font-size has-red-color archive-reportagen__counter no-margin__bottom">
                {data.illustrator.illustratorImageCount?.illustrationCount > 0 &&  <span>
                      Illustrationen: {data.illustrator.illustratorImageCount?.illustrationCount}
                </span>}
              
                {data.illustrator.illustratorImageCount?.infographicCount > 0 && <span>
                    Infografiken: {data.illustrator.illustratorImageCount?.infographicCount}
              </span>}
              </p>
              {data.illustrator.ACF_IllustratorIn_Fields?.biography && <div className="cell has-font__sectra-grotesk archive-reportagen__biography" itemProp="description">
                {HTML2React(data.illustrator.ACF_IllustratorIn_Fields.biography, { a: ReplaceLinks, })}
              </div>}
              <section className="archive-reportagen__content grid-container" itemProp="text">
                <div className="grid-x grid-padding-x grid-margin-x">
                {illustrations?.map(image => 
                  <Link to={image.reportage_route} key={image.attachment_id} className={`cell has-text-decoration-none ${image.classname && image.classname}`}>
                     <figure itemScope itemType="https://schema.org/VisualArtwork">
                         <LazyLoadImage
                             src={image.attachment_url}
                             srcSet={image.attachment_srcset ? image.attachment_srcset : null}
                             width={image.attachment_width}
                             height={image.attachment_height}
                             wrapperProps={{style: {height: "100%", width: "100%", display: "block" }}}
                             effect="opacity"
                         />
                      <figcaption itemProp="name">{image.magazine_issue} {image.reportage_title}</figcaption>
                     </figure>
                  </Link>)}
                  {pageNr.current + 1 < maxPages.current && (
                  <div className="cell">                       
                    <button className="has-red-background-color" onClick={LoadMore}>Mehr laden</button>
                  </div>
                  )}
                </div>
              </section>
            </div>
          </article>
        </main>
      <Footer/>
    </>
  );
}

export default Illustrator;
