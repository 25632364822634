const snackbarReducer = (state = {
    showSnackbar: false,
    snackbarMessage: null,
  }, action) => {
    switch (action.type) {
      case "SNACKBAR_OPEN":
        return {
            ...state,
            showSnackbar: true,
            snackbarMessage: action.payload,
          };
      case "SNACKBAR_CLOSE":
        return {
            ...state,
            showSnackbar: false,
            snackbarMessage: null,
          };
      default:
        return state;
    }
  };
  
  export default snackbarReducer;
  