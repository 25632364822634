import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import packageJSON from "../../../../package.json";

function Footer({ color }) {
  const fallbackColor = useSelector((state) => state.latestIssue);
  const footerMenu = useSelector((state) => state.menu.footer);

  return (
    <footer
      id="page-footer"
      className="footer grid-container full has-font__sectra-grotesk"
      style={{ background: color ? color : fallbackColor.mainColor }}
      role="contentinfo"
    >
      <div className="inner-footer grid-x grid-margin-x grid-padding-x">
        <div className="cell">
          <nav role="navigation">
            <ul
              id="footer-menu"
              className="menu__footer no-bullet has-small-font-size"
            >
              {footerMenu?.map((item) => (
                <li key={item.id} className="menu-item">
                  {item.connectedNode ? (
                    <Link to={item.connectedNode.node.uri}>{item.label}</Link>
                  ) : (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.label}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="cell">
          <a id="manage-cookie-consent" className="has-small-font-size">
            Privatsphäre-Einstellungen
          </a>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          right: "20px",
          marginTop: "10px",
          fontSize: "12px",
        }}
      >
        <span className="has-dark-gray-color">v{packageJSON.version}</span>
      </div>
    </footer>
  );
}

export default Footer;
