import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import HTML2React from "html2react";
import { Helmet } from "react-helmet-async";
import {Link} from "react-router-dom"
import Loading from "../../utility/Loading";
import Error from "../Error";
import Footer from "../../layout/Footer"
import Toolbar from "./Toolbar";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../../css/LazyLoadImage.css";
import { useSelector } from "react-redux";

function News(props) {
  const { slug } = props.match.params;
  const [date, setDate] = useState("");
  const selectedSize = useSelector((state) => state.fontSize);
  const { loading, error, data, refetch } = useQuery(GQL_Query.GET_NEWS_BY_SLUG, {
    variables: { slug: slug },
    onError(err) {
      // Error handling here
      //console.log(err.message);
      if(navigator.onLine) refetch({fetchPolicy: "network-only"})  
    },
    onCompleted(data) {     
      const date = new Date(data.post.date);
      const options = { year: "numeric", month: "long", day: "numeric" };
      const date_f = date.toLocaleDateString("de-DE", options);
      setDate(date_f);
    },
  });

  const ReplaceLinks = (props) => {
    if(props.href?.includes("http")) {
        return <a {...props} target="_blank" rel="noopener noreferrer"/>
    }
    else {
      return <Link {...props} to={props.href} />
    }
  }

  const Image = (image) => {
    return <LazyLoadImage
        alt={image.alt}
        src={image.src}
        srcSet={image.srcSet}
        width={image.width}
        height={image.height}
        style={image.style}
        effect="opacity"
    />;
  };

  if (loading) return <Loading/>
  if (error) return <Error/>
  if (!data.post) return <Error/>

  return (
    <>
    <Toolbar title={data.post.title} slug={data.post.slug} />
    <Helmet>{HTML2React(data.post.seo.fullHead)}</Helmet>
      <div className="grid-container">
        <div className="inner-content grid-x grid-margin-x grid-padding-x">
          <main
            className="main news-events small-12 has-font__sectra-grotesk cell"
            role="main"
          >
            <article itemScope itemType="https://schema.org/BlogPosting">
              <section className="article-header has-blue-color">
                <p className="date has-medium-font-weight">{date}</p>
                <h2
                  className="entry-title single-title has-font__sectra-grotesk-medium"
                  itemProp="name"
                >
                  {data.post.title}
                </h2>
                <p className="lead has-medium-font-weight">{data.post.excerptRaw}</p>
              </section>

              <section className="entry-content" itemProp="text">
                {data.post.featuredImage && 
                  <figure className="featured-image">
                    <LazyLoadImage
                        width={data.post.featuredImage?.node.mediaDetails.width}
                        height={data.post.featuredImage?.node.mediaDetails.height}
                        src={data.post.featuredImage?.node.mediaItemUrl}
                        className="attachment-full size-full wp-post-image"
                        alt={data.post.featuredImage?.node.altText}
                        srcSet={data.post.featuredImage?.node.srcSet}
                        sizes={data.post.featuredImage?.node.sizes}
                        wrapperProps={{style: {height: "100%", width: "100%", display: "flex" }}}
                        effect="opacity"
                    />

                    {data.post.featuredImage.node.caption && 
                      <figcaption>
                        {HTML2React(data.post.featuredImage.node.caption, { a: ReplaceLinks })}
                      </figcaption>
                    }
                  </figure>
                }

                {HTML2React(data.post.content, {
                  a: ReplaceLinks,
                  img: Image,
                })}
              </section>

              <section className="article-footer">
                <Link to="/news" className="button has-blue-background-color">
                  Alle News
                </Link>
              </section>
            </article>
          </main>
        </div>
      </div>
    <Footer/>
    </>
  );
}

export default News;
