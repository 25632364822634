import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../../css/LazyLoadImage.css";

function MagazinePreview({ magazine, isLoggedIn, isSubscribed }) {
  const link = `/magazin/${magazine.slug}`;

  return (
    <div className="cell small-12 medium-6 large-4 text-center archive-magazin__cover-cell">
      <Link to={link}>
        <figure className="archive-magazin__cover-cell--cover">
            <LazyLoadImage
                key={magazine.ACF_Magazin_Fields.cover?.id}
                className="archive-magazin__cover-cell--cover"
                src={magazine.ACF_Magazin_Fields.cover?.sourceUrl}
                srcSet={magazine.ACF_Magazin_Fields.cover?.srcSet}
                wrapperProps={{style: {height: "100%", width: "100%", display: "flex" }}}
                effect="opacity"
            />
        </figure>
      </Link>
      <div className="archive-magazin__cover-cell--issue">
        #{magazine.ACF_Magazin_Fields.issue} /{" "}
        {magazine.ACF_Magazin_Fields.month} {magazine.ACF_Magazin_Fields.year}
      </div>
    </div>
  );
}

export default MagazinePreview;
