import React, { useState } from "react";
import GQL_Mutation from "../../../services/graphql/mutations";
import GQL_Query from "../../../services/graphql/queries";
import { useMutation, useQuery } from "@apollo/client";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  UserLoggedIn,
  SetUserData,
  AddBookmark,
  OpenSnackbar,
} from "../../../services/redux/actions";
import { login } from "../../../services/apollo/authClient";
import shopClient from "../../../services/apollo/shopClient";
import Loading from "../../utility/Loading";
import HTML2React from "html2react";
import { Helmet } from "react-helmet-async";
import Error from "../Error";

function Login() {
  const dispatch = useDispatch();
  //const loginCheckDone = useSelector((state) => state.loginCheckDone);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const seoImage = useSelector((state) => state.seoImage);
  const prevPath = useSelector((state) => state.path.previous);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const {
    loading: textLoading,
    data,
    error: textError,
    refetch,
  } = useQuery(GQL_Query.GET_LOGIN_TEXT_BLOCK, {
    onError(err) {
      // Error handling here
      //console.log(err.message);
    },
    onCompleted(data) {
      //console.log(data);
      if (navigator.onLine) refetch({ fetchPolicy: "network-only" });
    },
  });

  const [loginUser, { error, loading }] = useMutation(GQL_Mutation.LOGIN_USER, {
    variables: {
      user: username,
      password: password,
    },
    client: shopClient,
    onError(err) {
      // Error handling here
      console.log(err.message);
    },
    onCompleted(data) {
      //console.log("Login Mutation data:", data);
      localStorage.setItem("refreshToken", data.login.user.jwtRefreshToken);
      const { jwtAuthToken, jwtAuthExpiration } = data.login.user;
      login(jwtAuthToken, jwtAuthExpiration);
      const user = {
        id: data.login.user.id,
        email: data.login.user.email,
        username: data.login.user.username,
        firstName: data.login.user.firstName,
        lastName: data.login.user.lastName,
        subscriptionAccessStatus: data.login.user.subscriptionAccessStatus,
        subscriptions: data.login.user.subscriptions,
      };
      dispatch(SetUserData(user));
      data.login.user.bookmarks.map((bookmark) => {
        dispatch(AddBookmark(bookmark));
      });
      // save subscription + login status to local storage
      localStorage.setItem('rep_subscription', JSON.stringify(user.subscriptionAccessStatus));
      localStorage.setItem('rep_login',true);
      localStorage.setItem('lastLoginCheck', Date.now()); // Save the login timestamp
      dispatch(UserLoggedIn());
      dispatch(OpenSnackbar("Sie sind nun angemeldet."));
    },
  });

  const ReplaceLinks = (props) => {
    if (props.href?.includes("http")) {
      return <a {...props} target="_blank" rel="noopener noreferrer" />;
    } else {
      return <Link {...props} to={props.href} />;
    }
  };

  const sendLogin = (e) => {
    e.preventDefault();
    loginUser();
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleErrorMsg = (error) => {
    const isEmail = validateEmail(username);
    if (error === "invalid_username") {
      return "Es ist ein Fehler aufgetreten: Kein Konto zu diesem Benutzernamen vorhanden.";
    } else if (error === "invalid_email") {
      return "Es ist ein Fehler aufgetreten: Kein Benutzerkonto zu dieser E-Mail-Adresse vorhanden.";
    } else if (error === "incorrect_password" && isEmail) {
      return "Es ist ein Fehler aufgetreten: E-Mail-Adresse und Passwort stimmen nicht überein.";
    } else if (error === "incorrect_password" && !isEmail) {
      return "Es ist ein Fehler aufgetreten: Benutzername und Passwort stimmen nicht überein.";
    }
    return "Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support.";
  };

  //if (!loginCheckDone) return <Loading/>
  if (isLoggedIn && prevPath?.includes("/reportage/"))
    return <Redirect to={`${prevPath}`} />;
  if (isLoggedIn) return <Redirect to={"/konto"} />;
  if (loading) return <Loading />;
  if (textLoading) return <Loading />;
  if (error && error.message.includes("Network")) return <Error />;
  if (textError) return <Error />;
  if (!data) return <Error />;

  return (
    <>
      <Helmet>
        <title>Anmeldung - Reportagen</title>
        <meta
          name="description"
          content="Anmeldung zum Benutzerkonto für Reportagen."
        />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Anmeldung - Reportagen" />
        <meta
          property="og:description"
          content="Anmeldung zum Benutzerkonto für Reportagen."
        />
        <meta property="og:site_name" content="Reportagen" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/reportagen"
        />
        <meta property="og:image" content={seoImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@reportagench" />
      </Helmet>
      <div className="login border-top">
        <div className="grid-container">
          <div className="grid-x grid-margin-x grid-padding-x">
            {data.acfOptionsFrontend.staticBlocks.blockUserWelcomeLogin && (
              <div className="cell">
                {HTML2React(
                  data.acfOptionsFrontend.staticBlocks.blockUserWelcomeLogin
                    .content,
                  { a: ReplaceLinks }
                )}
              </div>
            )}
            {error && (
              <div className="cell">
                <div className="has-yellow-background-color has-small-font-size rep-padding-all-50">
                  {handleErrorMsg(error.message)}
                </div>
              </div>
            )}
            <form className="login__form cell">
              <div className="login__username form__group">
                <input
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  type="text"
                  placeholder="E-Mail oder Benutzername"
                  required
                  className="form__field"
                  id="username"
                />
                <label className="form__label" htmlFor="username">
                  E-Mail oder Benutzername
                </label>
              </div>
              <div className="login__password form__group">
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  type="password"
                  placeholder="Passwort"
                  required
                  className="form__field"
                  id="password"
                />
                <label className="form__label" htmlFor="password">
                  Passwort
                </label>
              </div>
              <button disabled={!password || !username} onClick={sendLogin}>
                Anmelden
              </button>
            </form>
            <div className="login__register cell">
              <a
                href="https://shop.reportagen.com/mein-konto/#register"
                target="_blank"
                rel="noopener noreferrer"
              >
                Konto erstellen
              </a>
            </div>
            <div className="login__register cell">
              <a
                href="https://shop.reportagen.com/mein-konto/passwort-vergessen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Passwort zurücksetzen
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
