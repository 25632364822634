const pathReducer = (state = {    
    current: null,
    previous: null,
    entry: null
  }, action) => {
    switch (action.type) {
      case "SET_CURRENT_PATH":
        return {
            ...state,           
            current: action.payload
          };
      case "SET_PREVIOUS_PATH":
        return {
            ...state,
            previous: action.payload
          };
      case "SET_ENTRY_PATH":
        return {
            ...state,
            entry: action.payload
          };
      
                
      default:
        return state;
    }
  };
  
  export default pathReducer;
  