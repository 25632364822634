import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import AuthorBlock from "./AuthorBlock";
import Loading from "../../utility/Loading";
import Error from "../Error";
import { Helmet } from "react-helmet-async";
import HTML2React from "html2react";

function Authors() {
  const [authors, setAuthors] = useState({})

  const { data, loading, error, refetch } = useQuery(GQL_Query.GET_AUTHORS, {       
    onCompleted(data) {      
      setAuthors(JSON.parse(data.menuAutorinnen?.json))
      if(navigator.onLine) refetch({fetchPolicy: "network-only"})           
    },
  });

  
  if (loading) return <Loading/>
  if (error) return <Error/>
  if (!data.menuAutorinnen?.json) return <Error/> 
  

  return (
    <>
    <Helmet>{HTML2React(data.seo.contentTypes.autorin.archive.fullHead)}</Helmet>
    <div className="border-top"></div>
    <div className="grid-container">
        <div className="submenu archive-page__authors grid-x grid-margin-x grid-padding-x">
                <div className="submenu__children--container cell">
                    <div className="submenu__children--container-meta">
                        <h1 className="h3 has-font__sectra-grotesk-medium has-black-color">Autor:innen für <span className="has-font-style-italic">Reportagen</span> seit 2010</h1>
                        <small className="has-font__sectra-grotesk term-count has-red-color"><span id="person-count">{authors.total_count}</span> Personen</small>

                        <div className="grouped-list__container">
                         {Object.entries(authors).map(([key, value]) => {
                                if (key !== "total_count") {
                                     return <AuthorBlock key={key} letter={key} authors={value.names}/>
                                }
                            })
                          }
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
  );
}

export default Authors;
