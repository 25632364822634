import React from "react";

function User({ user }) {
  return (
    <div className="cell">
      <p className="has-font__sectra-grotesk rep-margin-top-100 no-margin__bottom has-dark-gray-color">Angemeldet als:</p>
      <p className="no-margin__bottom">
      {user.firstName && user.lastName ? <>{user.firstName} {user.lastName}<br/></> : <>{user.username}<br/></>}
          {user.email}<br />
          
      </p>
    </div>
  );
}

export default User;
