import React, {useEffect, useState, useRef} from 'react'
import { useLazyQuery } from "@apollo/client";
import GQL_Query from "../../../../services/graphql/queries";
import SearchInputField from "./Search/SearchInput";
import SearchResult from './Search/SearchResult';
import MenuContent from "./MenuContent";
import MenuFooter from "./MenuFooter";
import { useDispatch, useSelector} from "react-redux";
import {ResetSearchFieldInput, SaveSearchFieldInput} from "../../../../services/redux/actions"


function GeneralMenu({handleMenuSelection, closeMenu}) {
  const dispatch = useDispatch()
  const pageNr = useRef(null);  
  const maxPages = useRef(null)
  const resultCount = useRef(null)
  const reportagesIds = useRef(null)
  const [searchInput, setSearchInput] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [searchStatus, setSearchStatus] = useState("")
  const reduxSearchInput = useSelector(state => state.searchInput)
  

   

  const [SearchForKeywoard, {refetch: refetchResults}] = useLazyQuery(GQL_Query.GET_SEARCH_KEYWORD_IDS, {
    onError(err) {
      // Error handling here
      //console.log(err.message);
    },
    //fetchPolicy: "cache-and-network",
    onCompleted(data) {
      pageNr.current = 0;
      maxPages.current = data.reportagenSearch.maxNumPages;
      resultCount.current = data.reportagenSearch.foundResults;

      if(data.reportagenSearch.foundResults > 0) {
        dispatch(SaveSearchFieldInput(searchInput)) 
        reportagesIds.current = JSON.parse(data.reportagenSearch.resultPageIDs)
        const ids = JSON.parse(data.reportagenSearch.resultPageIDs)        
        GetReportagesData({          
          variables: { ids: ids[0].map(i=>Number(i)) },
        });
        if(navigator.onLine) refetchResults({fetchPolicy: "network-only"})  
        
      } else {
        setSearchStatus("noresults")
      }   
    },
  });

  const [GetReportagesData, {loading, refetch}] = useLazyQuery(GQL_Query.GET_SEARCH_REPORTAGES_BY_IDLIST, {
    onError(err) {
      // Error handling here
      //console.log(err.message);
    },
    //fetchPolicy: "cache-and-network",
    onCompleted(data) {      
      setSearchResults(searchResults.concat(data.reportages.nodes))
      setSearchStatus("finished")
      if(navigator.onLine) refetch({fetchPolicy: "network-only"})  
    },
  });

  const handleSearchInput = (e) => {       
      setSearchInput(e.target.value)
      setSearchStatus("")
      if(e.target.value.length > 3) {
        startResearch(e.target.value)
      }
  }

  const startResearch = (searchValue) => {
    setSearchStatus("loading")
    setSearchResults([])
    SearchForKeywoard({
      variables: { term: searchValue, perPage: 10 },
  });
  }

  const loadMore = () => {   
    GetReportagesData({
      variables: { ids: reportagesIds.current[pageNr.current+1].map(i=>Number(i)) },
    });
    pageNr.current = pageNr.current + 1;
  }

  const resetSearch = () => {
      setSearchInput("")
      setSearchStatus("")
      dispatch(ResetSearchFieldInput()) 
  }

  useEffect(() => {    
    if(reduxSearchInput) {
      setSearchStatus("")
      startResearch(reduxSearchInput)
      setSearchInput(reduxSearchInput)
    }
  }, [])


  return (
      <>
          <SearchInputField handleSearch={handleSearchInput} searchTerm={searchInput} resetSearch={resetSearch} status={searchStatus} resultCount={resultCount.current} />
          { searchInput.length > 3 && searchResults.length > 0 && <SearchResult results={searchResults} currentPage={pageNr.current} maxPages={maxPages.current} loadMore={loadMore} closeMenu={closeMenu} loading={loading}/> }
          { !searchInput &&
              <>
                  <MenuContent handleMenuSelection={handleMenuSelection} closeMenu={closeMenu}/>
                  <MenuFooter closeMenu={closeMenu}/>
              </>
          }
      </>
  )
}

export default GeneralMenu

