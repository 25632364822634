import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import client from "./services/apollo/client";
import store from "./services/redux/store"
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { HelmetProvider } from 'react-helmet-async';
import ScrollMemory from 'react-router-scroll-memory';

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <BrowserRouter>      
        <React.StrictMode>
          <HelmetProvider>
            <ScrollMemory />
            <App/>
          </HelmetProvider>
        </React.StrictMode>
      </BrowserRouter>
    </ApolloProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();