import React, { useState, useRef, useEffect } from "react";
import NewsArticle from "./NewsArticle";
import { useQuery, useLazyQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import Loading from "../../utility/Loading";
import Error from "../Error";
import Footer from "../../layout/Footer";
import { Helmet } from "react-helmet-async";
import HTML2React from "html2react";
import { useSelector } from "react-redux";

function NewsArchive() {
  const pageInfo = useRef(null);
  const [news, setNews] = useState([]);
  const blogPageId = useSelector(state => state.systemPages.blog);  

  const { data, loading, error, refetch } = useQuery(GQL_Query.GET_NEWS, {
    variables: {
      id: blogPageId,
      limit: 10
    },
    onError(err) {
      //console.log(err)
    },
    onCompleted(data) {      
      if(navigator.onLine) refetch({fetchPolicy: "network-only"})  
    },
  });

  const [LoadMoreNews, {loading: loadingMore, refetch: refetchMore}] = useLazyQuery(GQL_Query.GET_MORE_NEWS, {
    onError(err) {
      // Error handling here
      //console.log(err.message);
    },
    onCompleted(data) {
      setNews(news.concat(data.posts.nodes));
      pageInfo.current = data.posts.pageInfo;
      if(navigator.onLine) refetchMore({fetchPolicy: "network-only"})  
    },
  });

  const LoadMore = () => {
    LoadMoreNews({
      variables: { limit: 10, after: pageInfo.current.endCursor },
    });
  };


  useEffect(() => {
    if(data) {
      setNews(data.posts.nodes);
      pageInfo.current = data.posts.pageInfo;
    }
     
  }, [data])

 // if (pageIdLoading) return <Loading/>
  if (loading) return <Loading/>  
  if (error) return <Error/>
  if (!data) return <Loading/> 
 

  
  return (
    <>
    {data.page && <Helmet>{HTML2React(data.page.seo.fullHead)}</Helmet>}
      <section className="section-header has-blue-background-color has-white-color">
        <h2 className="h3 is-uppercase text-center has-font__sectra-grotesk-medium">
          News
        </h2>
      </section>

      <div className="grid-container">
        <div className="inner-content grid-x grid-margin-x grid-padding-x" style={{"maxWidth":"100vw"}}>
          <main
            className="main news-events small-12 has-font__sectra-grotesk cell"
            role="main"
          >
            {news.map((news) => (
              <NewsArticle key={news.id} news={news} />
            ))}
            <section className="article-footer">
              <button
                onClick={LoadMore}
                className={`has-red-background-color ${loadingMore && `loading-more`}`}
              >
                Mehr laden
              </button>
            </section>
          </main>
        </div>
      </div>

    <Footer/>
    </>
  );
}

export default NewsArchive;