export const UserLoggedIn = () => {
  return {
    type: "USER_LOGGED_IN",
  };
};

export const UserLoggedOut = () => {
  return {
    type: "USER_LOGGED_OUT",
  };
};

export const SetUserData = (data) => {
  return {
    type: "SET_USER_DATA",
    payload: data,
  };
};

export const DelUserData = () => {
  return {
    type: "DEL_USER_DATA",
  };
};

export const AddBookmark = (data) => {
  return {
    type: "ADD_BOOKMARK",
    payload: data,
  };
};

export const DelBookmark = (data) => {
  return {
    type: "DEL_BOOKMARK",
    payload: data,
  };
};

export const ClearBookmarks = () => {
  return {
    type: "CLEAR_BOOKMARKS",
  };
};

export const OpenMenu = () => {
  return {
    type: "MENU_OPEN",
  };
};

export const CloseMenu = () => {
  return {
    type: "MENU_CLOSE",
  };
};

export const SetLatestIssue = (data) => {
  return {
    type: "SET_LATEST_ISSUE",
    payload: data
  };
};

export const LoginCheckDone = () => {
  return {
    type: "LOGIN_CHECK_DONE",
  };
};

export const SetFontSize = (data) => {
  return {
    type: "SET_FONT_SIZE",
    payload: data,
  };
};

export const OpenSnackbar = (data) => {
  return {
    type: "SNACKBAR_OPEN",
    payload: data
  };
};

export const CloseSnackbar = () => {
  return {
    type: "SNACKBAR_CLOSE",
  };
};

export const SetFooterMenu = (data) => {
  return {
    type: "SET_FOOTER_MENU",
    payload: data
  };
};

export const SetSectionsMenu = (data) => {
  return {
    type: "SET_SECTIONS_MENU",
    payload: data
  };
};

export const SetPagesMenu = (data) => {
  return {
    type: "SET_PAGES_MENU",
    payload: data
  };
};

export const SetAuthorsMenu = (data) => {
  return {
    type: "SET_AUTHORS_MENU",
    payload: data
  };
};

export const SetIllustratorsMenu = (data) => {
  return {
    type: "SET_ILLUSTRATORS_MENU",
    payload: data
  };
};

export const SetTopicsMenu = (data) => {
  return {
    type: "SET_TOPICS_MENU",
    payload: data
  };
};

export const SetRegionsMenu = (data) => {
  return {
    type: "SET_REGIONS_MENU",
    payload: data
  };
};

export const SetSeriesMenu = (data) => {
  return {
    type: "SET_SERIES_MENU",
    payload: data
  };
};

export const SetSeriesArchiveMenu = (data) => {
  return {
    type: "SET_SERIES_ARCHIVE_MENU",
    payload: data
  };
};

export const LoadingContentDone = () => {
  return {
    type: "LOADING_CONTENT_DONE",
  };
};

export const SetSeoFallbackImage = (data) => {
  return {
    type: "SET_SEO_FALLBACK_IMAGE",
    payload: data
  };
};

export const SetCurrentPath = (data) => {
  return {
    type: "SET_CURRENT_PATH",
    payload: data
  };
};

export const SetPreviousPath = (data) => {
  return {
    type: "SET_PREVIOUS_PATH",
    payload: data
  };
};

export const SetEntryPath = (data) => {
  return {
    type: "SET_ENTRY_PATH",
    payload: data
  };
};

export const SaveSearchFieldInput = (data) => {
  return {
    type: "SET_SEARCH_INPUT",
    payload: data
  };
};

export const ResetSearchFieldInput = () => {
  return {
    type: "DEL_SEARCH_INPUT"
  };
};

export const SetBlogPage = (id) => {
  return {
    type: "SET_BLOG_PAGE",
    payload: id
  };
};

export const SetHomePage = (id) => {
  return {
    type: "SET_HOME_PAGE",
    payload: id
  };
};