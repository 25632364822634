import React from "react";

function LayerMenu({ handleMenuSelection, title, menu }) {
  return (
    <div className="submenu">
      <a className="submenu__parent menu-item">
        <span>{title}</span>
      </a>
      <ul className="submenu__children submenu__children--primary">
        {menu.map((item) => (
          <li
            className="submenu__child menu-item"
            key={item.id}
            onClick={() => handleMenuSelection(`${item.slug}Menu`)}
          >
            <span tabIndex="0" role="button">
              {item.name}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LayerMenu;
