import React, { useState, useRef } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import Error from "../Error";
import { Link } from "react-router-dom";
import HTML2React from "html2react";
import Footer from "../../layout/Footer"
import Loading from "../../utility/Loading"
import { Helmet } from "react-helmet-async";

function Author(props) {  
  const { slug } = props.match.params;
  const pageInfo = useRef(null);
  const [reportages, setReportages] = useState([]);  

  const { loading, error, data, refetch } = useQuery(
    GQL_Query.GET_AUTHOR_BY_SLUG,
    {
      variables: { slugID: slug,
      slugString: slug,
      limit: 10 },
      onError(err) {
        // Error handling here
        //console.log(err.message);
      },
      onCompleted(data) {        
        setReportages(data.reportages.nodes);       
        pageInfo.current = data.reportages.pageInfo;
        if(navigator.onLine) refetch({fetchPolicy: "network-only"})
      },
    }
  );

  const [LoadReportages, {loading: loadingMore, refetch: refetchMore}] = useLazyQuery(GQL_Query.GET_AUTHOR_BY_SLUG, {
    onError(err) {
      // Error handling here
      //console.log(err.message);
    },
    onCompleted(data) {
      setReportages(reportages.concat(data.reportages.nodes));
      pageInfo.current = data.reportages.pageInfo;
      if(navigator.onLine) refetchMore({fetchPolicy: "network-only"})
    },
  });
  

  const LoadMore = () => {
    //console.log("load more..");
    LoadReportages({
      variables: { slugID: slug,
        slugString: slug,
        limit: 10, after: pageInfo.current.endCursor },
    });
  };

  const ReplaceLinks = (props) => {
    if(props.href?.includes("http")) {
        return <a {...props} target="_blank" rel="noopener noreferrer"/>
    }
    else {
      return <Link {...props} to={props.href} />
    }
  }


  if (loading) return <Loading/>
  if (error) return <Error/>
  if (!data.autorin) return <Error/> 
  
  return (
    <>
    <Helmet>{HTML2React(data.autorin.seo.fullHead)}</Helmet>
    <div className="border-top"></div>
      <main className="main" role="main">
        <article className="grid-container" itemProp="author" itemScope itemType="http://schema.org/Person">
          <div className="grid-x grid-padding-x grid-margin-x archive-reportagen__counter">
            <header className="archive-reportagen__header cell">
              <p className="archive-reportagen__header--meta has-font__sectra-grotesk has-dark-gray-color">
                  <small>Ergebnisse für</small>
              </p>
              <h1 className="h3 has-font__sectra-grotesk-bold" itemProp="name">
              {data.autorin.title}
              </h1>
            </header>
            <p className="cell has-font__sectra-grotesk has-small-font-size has-red-color archive-reportagen__counter no-margin__bottom">
              {data.autorin.authorTextCount?.reportageCount > 0 &&  <span>
                      Reportagen: {data.autorin.authorTextCount?.reportageCount}
              </span>}
            
              {data.autorin.authorTextCount?.seriesCount > 0 && <span>
                      Artikel: {data.autorin.authorTextCount?.seriesCount}
            </span>}
            </p>
            {data.autorin.ACF_AutorIn_Fields.biography && <div className="cell has-font__sectra-grotesk archive-reportagen__biography" itemProp="description">
              {HTML2React(data.autorin.ACF_AutorIn_Fields.biography, { a: ReplaceLinks, })}
            </div>}

            <section className="archive-reportagen__content grid-container" itemProp="text">
              <div className="grid-x grid-padding-x grid-margin-x">
                {reportages.map(reportage => <Link key={reportage.id} to={reportage.uri} className={`cell has-text-decoration-none search-result  ${reportage?.searchResultClass && reportage.searchResultClass}`}>
                      {HTML2React(reportage.searchResultMarkup)}
                </Link>)}
                {pageInfo.current?.hasNextPage && (
                <div className="cell">                       
                  <button className={`has-red-background-color ${loadingMore && `loading-more`}`} onClick={LoadMore}>Mehr laden</button>
                </div>
                )}
              </div>
            </section>          
          </div>
        </article>
      </main>

    <Footer/>
    </>
  );
  
}

export default Author;
