import {useState, useEffect} from 'react'

function ScrollHandler() {
    const [scroll, setScroll] = useState({    
        y: window.pageYOffset,
        direction: "",
        top: true
      })  
    
    const handleScroll = (e) => {    
    setScroll(prev => ({     
        y: window.pageYOffset,
        // Here we’re comparing the previous state to the current state to get the scroll direction
        direction: prev.y > window.pageYOffset ? 'up' : 'down',
        top: window.pageYOffset < 70 ? true : false
    }))     
    }
    
    useEffect(() => {    
    if(scroll.top) {
        document.body.classList.remove("scroll-down");
    }
    else if (scroll.direction === "down") {
        if(!document.body.classList.contains("menu-open")) {
            document.body.classList.remove("scroll-up");
            document.body.classList.add("scroll-down");
        }
    }
    else if (scroll.direction === "up") {
        if(!document.body.classList.contains("menu-open")) {
            document.body.classList.remove("scroll-down");
            document.body.classList.add("scroll-up");
        }
    }
    }, [scroll]);

    useEffect(() => {    
    window.addEventListener("scroll", handleScroll);
    
    return () => {
        window.removeEventListener("scroll", handleScroll);
    };
    }, []);

    return (null)
}

export default ScrollHandler
