const loginCheckDone = (state = false, action) => {
    switch (action.type) {
      case "LOGIN_CHECK_DONE":
        return true;      
      default:
        return state;
    }
  };
  
  export default loginCheckDone;
  