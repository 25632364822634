import React from "react";
import "../css/fonts.css";
import "../css/App.css";

import TagManager from "react-gtm-module";

// Components
import Header from "./layout/Header";
import Content from "./layout/Content";
import Menu from "./layout/Menu";
import Snackbar from "./utility/Snackbar";

// Handling functions and preloading content
import LoginHandler from "./utility/LoginHandler";
import ScrollHandler from "./utility/ScrollHandler";
import PreloadContent from "./utility/PreloadContent";

// init Google Tag Manager
const tagManagerArgs = {
  gtmId: "GTM-T7CX57Z", // live
  //gtmId: "GTM-P6MB23L", // test
};

TagManager.initialize(tagManagerArgs);


function App() {
  //const dispatch = useDispatch();
  // const preloadingContent = useSelector(state => state.loadingContentDone);
  // const loginCheckDone = useSelector(state => state.loginCheckDone);
  // const isMenuOpen = useSelector((state) => state.isMenuOpen);

  window.dataLayer.push({
    event: "page_view",
  });

  //console.log("-- Apps.js --")

  return (
    <PreloadContent>
      <ScrollHandler />
      <LoginHandler>
        <div className="App">
          <Header />
          <Snackbar />
          <Menu />
          <Content />
        </div>
      </LoginHandler>
    </PreloadContent>
  );
}

export default App;
