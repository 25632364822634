import React from "react";
import { useQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import { Link } from "react-router-dom";
import Error from "../Error";
import HTML2React from "html2react";
import Series from "./Series";
import Footer from "../../layout/Footer";
import { Helmet } from "react-helmet-async";
import Loading from "../../utility/Loading";
import { useSelector } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../../css/LazyLoadImage.css";

function Magazine(props) {
  const { slug } = props.match.params;
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isSubscribed = useSelector(
    (state) => state.userData.subscriptionAccessStatus
  );

  const { loading, error, data, refetch } = useQuery(
    GQL_Query.GET_MAGAZINE_BY_SLUG,
    {
      variables: { slug: slug },
      onCompleted(data) {
        //console.log(data.magazin)
        if (navigator.onLine) refetch({ fetchPolicy: "network-only" });
      },
    }
  );

  const ReplaceLinks = (props) => {
    if (props.href?.includes("http")) {
      return <a {...props} target="_blank" rel="noopener noreferrer" />;
    } else {
      return <Link {...props} to={props.href} />;
    }
  };

  const Image = (image) => {
    return <LazyLoadImage
        alt={image.alt}
        src={image.src}
        srcSet={image.srcSet}
        width={image.width}
        height={image.height}
        style={image.style}
        effect="opacity"
    />;
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data.magazin) return <Error />;

  return (
    <>
      <Helmet>{HTML2React(data.magazin.seo.fullHead)}</Helmet>
      <div className="magazineContent">
        <header
          className="section-header"
          style={{
            background: data.magazin.magazineColors.mainColor,
          }}
        >
          <h1 className="h3 is-uppercase text-center has-medium-font-weight">
            #{data.magazin.ACF_Magazin_Fields.issue}{" "}
            {data.magazin.ACF_Magazin_Fields.month}{" "}
            {data.magazin.ACF_Magazin_Fields.year}
          </h1>
        </header>
        <article itemScope itemType="http://schema.org/Periodical">
          <header
            className="magazin-header"
            style={{
              background: data.magazin.magazineColors.backgroundColorLight,
            }}
          >
            <div className="grid-container">
              <div className="grid-x grid-padding-x grid-margin-x">
                <div className="magazin-header__cover cell text-center">
                  <LazyLoadImage
                      src={data.magazin.ACF_Magazin_Fields.cover?.sourceUrl}
                      srcSet={data.magazin.ACF_Magazin_Fields.cover?.srcSet}
                      wrapperProps={{style: {height: "100%", width: "100%", display: "block" }}}
                      effect="opacity"
                  />
                </div>
                <div className="magazin-header__actions cell text-center">
                  {data.magazin.ACF_Magazin_Fields.productLink && (
                      <a
                        className="button no-margin__bottom"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={data.magazin.ACF_Magazin_Fields.productLink}
                      >
                        Print kaufen
                      </a>
                    )}
                  {!data.magazin.ACF_Magazin_Fields.productLink && (
                      <span className="button no-margin__bottom is-style-disabled has-font__sectra-grotesk-medium is-uppercase">
                        Ausverkauft
                      </span>
                    )}
                  <div className="magazin-header__actions--icons">
                  {isLoggedIn &&
                    isSubscribed &&
                    data.magazin.ACF_Magazin_Fields.pdfDownloadUrl && (
                      <a
                          className="magazin-header__actions--icon rep-margin-top-50"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://epaper.reportagen.com/?i=${data.magazin.ACF_Magazin_Fields.issue}&m=${encodeURIComponent(btoa(data.magazin.ACF_Magazin_Fields.pdfDownloadUrl))}`}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 145"><path d="M62.3 42.1c0 9.4-1.7 17.4-5.2 23.9-3.1 5.9-7.3 10.5-12.7 13.7s-11.5 4.8-18.6 4.8H16v60.8H0V0h25.9C33 0 39.2 1.6 44.5 4.7s9.5 7.6 12.6 13.6c3.4 6.5 5.2 14.4 5.2 23.8zm-16.5 0c0-6.1-1-11.1-3.1-14.9-1.7-3.3-4-5.8-6.9-7.6-2.9-1.7-6.3-2.6-10.3-2.6h-9.4v50.4h9.4c3.9 0 7.4-.9 10.3-2.7s5.2-4.4 6.9-7.7c2.1-3.8 3.1-8.8 3.1-14.9zm24 103.1V0h27.3c16.6 0 27.9 9.4 33.8 28.2 3.6 11.5 5.4 26.3 5.4 44.4s-1.8 32.9-5.4 44.4c-5.9 18.8-17.2 28.2-33.8 28.2H69.8zm27.4-17c8.6 0 14.7-6 18.3-18.1 2.8-8.4 4.3-21 4.3-37.6s-1.4-29.1-4.3-37.6C111.9 23 105.8 17 97.2 17H85.9v111.2h11.3zm65.8 17h-16V0h53v17h-37v47.1h35.1v17H163v64.1z"/></svg>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="magazin-content" itemProp="text">
            {HTML2React(data.magazin.content, { a: ReplaceLinks, img: Image })}
          </section>

          <section className="magazin-footer">
            <div className="grid-container">
              <div className="grid-x grid-padding-x grid-margin-x">
                {data.magazin.allRelatedSeries?.map((serie) => (
                  <Series key={serie.postId} serie={serie} />
                ))}
              </div>
            </div>
          </section>
        </article>
      </div>
      <Footer color={data.magazin.magazineColors?.mainColor} />
    </>
  );
}

export default Magazine;
