import React from 'react'
import LinkedMenuItem from "./LinkedMenuItem"

function MultiLinkMenu({closeMenu, title, submenu}) { 
    
    return (
        <li className="menu-item menu-item-has-children">
            <a><span>{title}</span></a>
            <ul className="menu">{                
                submenu.map((item) => <LinkedMenuItem key={item.id} item={item} closeMenu={closeMenu}/>)                
            }</ul>
        </li>
    )
}

export default MultiLinkMenu
