import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AddBookmark, DelBookmark, OpenSnackbar } from "../../../../../services/redux/actions";
import GQL_Mutation from "../../../../../services/graphql/mutations";
import { useMutation } from "@apollo/client";
import shopClient from "../../../../../services/apollo/shopClient";
import { inMemoryToken } from "../../../../../services/apollo/authClient";



function Toolbar({ reportage, toggleSharingMenu, toggleFontSizeMenu, hasEmailPass, emailPassToken }) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isSubscriber = useSelector((state) => state.userData?.subscriptionAccessStatus)
  const bookmarks = useSelector((state) => state.bookmarks);
  const [bookmark, setBookmark] = useState({})
  const [isBookmarked, setIsBookmarked] = useState(false);
  //console.log(reportage);

  useEffect(() => {
    if(isLoggedIn) {     
      if(bookmarks.some(bookmark => bookmark.bookmarkPostId === reportage.databaseId)){
       setIsBookmarked(true)
       setBookmark(bookmark)
    }
  }
        
  }, [isLoggedIn])

 
  const [addBookmarkToDB, {  }] = useMutation(
    GQL_Mutation.SAVE_BOOKMARK,
    {
      variables: {
        user: inMemoryToken?.token,
        id: reportage.databaseId
      },
      client: shopClient,
      onError(err) {
        // Error handling here
        console.log(err.message);       
      },
      onCompleted(data) {
        if (data.saveBookmark.validation) dispatch(OpenSnackbar(data.saveBookmark.message))
      },
    }
  );
  

  const [delBookmarkFromDB, {  }] = useMutation(
    GQL_Mutation.DELETE_BOOKMARK,
    {
      variables: {
        user: inMemoryToken?.token,
        id: reportage.databaseId
      },
      client: shopClient,
      onError(err) {
        // Error handling here
        console.log(err.message);        
      },
      onCompleted(data) {
        if (data.deleteBookmark.validation) dispatch(OpenSnackbar(data.deleteBookmark.message))
      },
    }
  );

 

  const handleClickSharing = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `Lesetipp: «${reportage.title}»`,
          text: `Jetzt lesen: «${reportage.title}» auf reportagen.com`,
          url: "https://reportagen.com/reportage/" + reportage.slug + "?t=" + emailPassToken,
        })
        .then(() => {
          //console.log("Successfully shared");
        })
        .catch((error) => {
          //console.error("Something went wrong sharing the article", error);
        });
    } else {
      toggleSharingMenu()
    }
  };

 

  const handleClickBookmark = () => {    
    if (isLoggedIn &&  isSubscriber) {
      if (isBookmarked) {
        // bookmarked -> delete        
        delBookmarkFromDB() // -> datenbank wordpress
        dispatch(DelBookmark({__typename: "Bookmark",
        bookmarkPostId: reportage.databaseId,
        bookmarkSlug: reportage.slug,
        bookmarkTitle: reportage.title,
        bookmarkClass: reportage.searchResultClass,
        bookmarkMarkup: reportage.searchResultMarkup})); // -> löschen aus redux store
      } else {
        // not bookmarked -> add     
        addBookmarkToDB()
        dispatch(AddBookmark({__typename: "Bookmark",
          bookmarkPostId: reportage.databaseId,
          bookmarkSlug: reportage.slug,
          bookmarkTitle: reportage.title,
          bookmarkClass: reportage.searchResultClass,
          bookmarkMarkup: reportage.searchResultMarkup}));
      }
      // reverse mark icon
      setIsBookmarked(!isBookmarked);
    } else {
      dispatch(OpenSnackbar("Diese Funktion steht nur Abonnent:innen zur Verfügung."))
    }        
  };



  const createPDF = () => {
    //ReactPDF.renderToStream(<CreatePDF />);
    //ReactPDF.render(<CreatePDF />, `${__dirname}/example.pdf`);
  }



  return (
    <>
      <div className="toolbar">
          <a target="_blank" className="toolbar__icon" href={`https://epaper.reportagen.com/?p=${encodeURIComponent(reportage.id)}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 145"><path d="M62.3 42.1c0 9.4-1.7 17.4-5.2 23.9-3.1 5.9-7.3 10.5-12.7 13.7s-11.5 4.8-18.6 4.8H16v60.8H0V0h25.9C33 0 39.2 1.6 44.5 4.7s9.5 7.6 12.6 13.6c3.4 6.5 5.2 14.4 5.2 23.8zm-16.5 0c0-6.1-1-11.1-3.1-14.9-1.7-3.3-4-5.8-6.9-7.6-2.9-1.7-6.3-2.6-10.3-2.6h-9.4v50.4h9.4c3.9 0 7.4-.9 10.3-2.7s5.2-4.4 6.9-7.7c2.1-3.8 3.1-8.8 3.1-14.9zm24 103.1V0h27.3c16.6 0 27.9 9.4 33.8 28.2 3.6 11.5 5.4 26.3 5.4 44.4s-1.8 32.9-5.4 44.4c-5.9 18.8-17.2 28.2-33.8 28.2H69.8zm27.4-17c8.6 0 14.7-6 18.3-18.1 2.8-8.4 4.3-21 4.3-37.6s-1.4-29.1-4.3-37.6C111.9 23 105.8 17 97.2 17H85.9v111.2h11.3zm65.8 17h-16V0h53v17h-37v47.1h35.1v17H163v64.1z"/></svg>
          </a>
          <a className="toolbar__icon" onClick={() => toggleFontSizeMenu()}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 645.61 512"><path d="M499.32 0h-90.7l-147 512h58.52l51.2-182.13h164.55L587.09 512h58.52zM386.68 277.94l57.05-202.6 5.12-39.5h10.24l4.39 39.5 57 202.6zM100 170.67L0 512h39.81l34.83-121.42h112L221.42 512h39.8l-99.51-341.33zM85.08 356l38.81-135.07 3.49-26.33h7l3 26.33L176.14 356z"/></svg>
          </a>
          <a className="toolbar__icon" onClick={() => handleClickBookmark()}>
              {isBookmarked ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 346.2 512"><path d="M328.7 512c-5 0-9.7-2.1-13-5.8L173.1 347.3 35 501.2l-4.5 5c-6.5 7.2-17.6 7.7-24.7 1.2A17.3 17.3 0 0 1 0 494.5v-477C0 7.8 7.9 0 17.5 0h311.2c9.7 0 17.5 7.8 17.5 17.5v477c0 9.7-7.8 17.5-17.5 17.5z"/></svg>
              : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 346.22 511.99"><path d="M328.71 512a17.47 17.47 0 0 1-13-5.82L173.1 347.28 35 501.16l-4.5 5A17.51 17.51 0 0 1 0 494.48v-477A17.51 17.51 0 0 1 17.51 0h311.2a17.51 17.51 0 0 1 17.51 17.51v477A17.52 17.52 0 0 1 328.71 512zM173.1 312.25a35 35 0 0 1 26.07 11.65l112 124.84V35H35v413.74q56-62.43 112-124.85a35.07 35.07 0 0 1 26.1-11.64z"/></svg> }
          </a>

          <a className="toolbar__icon" onClick={() => handleClickSharing()}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 362.56 511.99"><path d="M345.07 194.56a17.48 17.48 0 0 0-17.48 17.49V477H35V212a17.5 17.5 0 1 0-35 0v265a35 35 0 0 0 35 35h292.59a35 35 0 0 0 35-35V212a17.49 17.49 0 0 0-17.52-17.44zM84.16 125.64a17.48 17.48 0 0 0 24.69-1.34l54.85-61.14v313.32a17.5 17.5 0 1 0 35 0V63.17l54.85 61.13a17.49 17.49 0 0 0 26-23.36L194.2 5.81a17.48 17.48 0 0 0-26 0l-85.38 95.13a17.49 17.49 0 0 0 1.34 24.7z"/></svg>
          </a>

      </div>
    </>
  );
}

export default Toolbar;
