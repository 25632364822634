import React from 'react'
import HTML2React from "html2react";
import { Link } from "react-router-dom"


function SearchResult({closeMenu, results, currentPage, maxPages, loadMore, loading}) {   
    
    return (
        <div>
            {results.map(result => <Link onClick={()=> closeMenu()} to={result.uri} className={`search-result ${result.searchResultClass && result.searchResultClass}`} key={result.id}>{HTML2React(result.searchResultMarkup)}</Link>)}
            {currentPage + 1 < maxPages && (
            <div className="cell">                       
                <button className={`has-red-background-color ${loading && `loading-more`}`} onClick={loadMore}>Mehr laden</button>
            </div>
            )}
        </div>
    )
}

export default SearchResult
