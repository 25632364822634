import React from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import Content from "./Content";
import Paywall from "./Paywall";
import Loading from "../../utility/Loading";
import { useLocation } from "react-router-dom";
import Error from "../Error";

function Reportage(props) {
  //console.log("-- Reportage/index.js --")
  const { slug } = props.match.params;
  //console.log(slug);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("t");
  //console.log(token)
  //const loginCheckDone = useSelector((state) => state.loginCheckDone);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isSubscribed = useSelector(
    (state) => state.userData.subscriptionAccessStatus
  );

  const { loading, error, data, refetch } = useQuery(
    GQL_Query.GET_REPORTAGE_BASE_OPTIONS,
    {
      //fetchPolicy: "cache-and-network",
      variables: { slug: slug },
      onCompleted(data) {
        //console.log(data)
        if (navigator.onLine) refetch({ fetchPolicy: "network-only" });
      },
    }
  );

  // check if locked or not
  // check if token is given & is valid

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data.reportage) return <Error />;
  // //if (!loginCheckDone) return <Loading/>
  if (token && token === data.reportage.emailPassToken)
    return <Content hasEmailPass slug={slug} emailPassToken={data.reportage.emailPassToken} />;
  if (isLoggedIn && isSubscribed)
    return <Content isLocked slug={slug} emailPassToken={data.reportage.emailPassToken} />;
  if (!data.reportage.Reportagen_Options.locked)
    return <Content isFree slug={slug} emailPassToken={data.reportage.emailPassToken} />;

  return (
    <Paywall isLoggedIn={isLoggedIn} isSubscribed={isSubscribed} slug={slug} />
  );

  //return("-- Reportage/index.js --")
}

export default Reportage;
