import React, {useState} from 'react'
import LetterBlock from "./LetterBlock"

function AuthorsMenu({handleMenuSelection, closeMenu, title, authors}) {    
    const [currentSelection, setCurrentSelection] = useState({})
    

    const handleLetterSelection = (letter) => {        
        Object.entries(authors).map(([key, value]) => {
            if (letter === key) {                
                setCurrentSelection(value)
            }
        })
    }
    
    return (
        <div className="submenu">
            <div className="submenu__children--container">
                <div className="submenu__children--container-meta">
                    <small className="taxonomy-name">Magazin</small>            
                    <h3 className="has-font__sectra-grotesk-medium has-black-color">{title}<a onClick={() => handleMenuSelection()} className="close-submenu"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 45.27L466.73 0 256 210.73 45.27 0 0 45.27 210.73 256 0 466.73 45.27 512 256 301.27 466.73 512 512 466.73 301.27 256z"></path></svg></a></h3>
                    <small className="term-count has-red-color"><span id="person-count">{Object.keys(currentSelection).length === 0 ? authors.total_count : currentSelection.count}</span> Personen</small>
                    <div className="letters-list">
                        <a onClick={() => setCurrentSelection({})} className={`close-submenu__letter ${Object.keys(currentSelection).length === 0 ? "has-black-color" : ""}`}><span>Alle</span></a>
                        {Object.keys(authors).map(letter => 
                            {
                                if (letter !== "total_count") return <a key={letter} onClick={() => handleLetterSelection(letter)} className={`open-submenu__letter ${letter === currentSelection.letter ? "has-black-color" : ""}`}><span>{letter}</span></a>
                            })
                        } 
                    </div>
                    <div className="grouped-list__container">
                    {Object.keys(currentSelection).length === 0 ?                     
                        Object.entries(authors).map(([key, value]) => {
                            if (key !== "total_count") {
                                 return <LetterBlock key={key} letter={key} authors={value.names} closeMenu={closeMenu}/>
                            }
                        })
                        : <LetterBlock letter={currentSelection.letter} authors={currentSelection.names} closeMenu={closeMenu}/>
                     }  
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthorsMenu
