import React from "react";

function Abo({ user }) {
  return (
    <div className="cell">
      <p className="has-font__sectra-grotesk has-dark-gray-color rep-margin-top-100 no-margin__bottom">Digitalzugang:</p>
      {user.subscriptionAccessStatus ? (
        <p>         
          Ihr Digitalzugang läuft {user.subscriptions[0].expires}.
        </p>
      ) : (
        <>
          <p>Sie haben derzeit keinen Digitalzugang.</p>
          <a className="wp-block-button__link" href="https://shop.reportagen.com/" target="_blank" rel="noreferrer noopener">Abonnieren</a>
        </>
      )}
    </div>
  );
}

export default Abo;
