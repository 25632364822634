import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UserLoggedIn,
  SetUserData,
  LoginCheckDone,
} from "../../services/redux/actions";
import GQL_Mutation from "../../services/graphql/mutations";
import GQL_Query from "../../services/graphql/queries";
import { useMutation, useLazyQuery } from "@apollo/client";
import { authClient, login } from "../../services/apollo/authClient";
import shopClient from "../../services/apollo/shopClient";
import { AddBookmark } from "../../services/redux/actions";
import Loading from "./Loading";

function LoginHandler(props) {
  const dispatch = useDispatch();
  const loginCheckDone = useSelector((state) => state.loginCheckDone);
  const refreshToken = localStorage.getItem("refreshToken");

  const [newToken, {}] = useMutation(GQL_Mutation.REFRESH_TOKEN, {
    variables: {
      token: localStorage.getItem("refreshToken"),
    },
    client: shopClient,
    onError(err) {
      // Error handling here
      //console.log(err.message);
      //console.log("clearing storage...")
      localStorage.clear();
      dispatch(LoginCheckDone());
      window.dataLayer.push({
        event: "loginCheckDone",
      });
    },
    onCompleted(data) {
      const { authToken } = data.refreshJwtAuthToken;
      login(authToken);
      loadUserData();
    },
  });

  const [loadUserData, { refetch }] = useLazyQuery(GQL_Query.GET_VIEWER, {
    client: authClient,
    onError(err) {
      // Error handling here
      //console.log("onError:", err.message);
      dispatch(LoginCheckDone());
      window.dataLayer.push({
        event: "loginCheckDone",
      });
    },
    onCompleted(userData) {
      //console.log(userData);
      const user = {
        id: userData.viewer.id,
        email: userData.viewer.email,
        username: userData.viewer.username,
        firstName: userData.viewer.firstName,
        lastName: userData.viewer.lastName,
        subscriptionAccessStatus: userData.viewer.subscriptionAccessStatus,
        subscriptions: userData.viewer.subscriptions,
      };
      dispatch(SetUserData(user));
      userData.viewer.bookmarks.map((bookmark) => {
        dispatch(AddBookmark(bookmark));
      });
      // save subscription + login status to local storage
      localStorage.setItem('rep_subscription', JSON.stringify(user.subscriptionAccessStatus));
      localStorage.setItem('rep_login',true);

      localStorage.setItem('lastLoginCheck', Date.now()); // Save the login time


      dispatch(UserLoggedIn());
      dispatch(LoginCheckDone());
      window.dataLayer.push({
        event: "loginCheckDone",
      });
      //document.dispatchEvent(event);
      if (navigator.onLine) refetch({ fetchPolicy: "network-only" });
    },
  });

  useEffect(() => {
    if (!loginCheckDone) {
      //console.log("login check not done")
      if (refreshToken) {
        //console.log("found refreshtoken -> login user");
        newToken();
      } else {
        localStorage.setItem('rep_login',false);
        localStorage.removeItem('lastLoginCheck');
        dispatch(LoginCheckDone());
        window.dataLayer.push({
          event: "loginCheckDone",
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginCheckDone]);

  if (loginCheckDone) return props.children;

  return <Loading />;
}

export default LoginHandler;
