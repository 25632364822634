import React from "react"
import {useEffect, useState} from "react"
import GQL_Query from "../../services/graphql/queries";
import { useQuery } from "@apollo/client";
import { SetEntryPath, SetLatestIssue, SetFooterMenu, SetSectionsMenu, SetPagesMenu, SetAuthorsMenu, SetIllustratorsMenu, SetSeriesMenu, SetSeriesArchiveMenu, SetTopicsMenu, SetRegionsMenu, LoadingContentDone, SetSeoFallbackImage, SetBlogPage } from "../../services/redux/actions";
import { useDispatch, useSelector} from "react-redux";
import { useLocation } from "react-router";
import Loading from "./Loading";
import Offline from "./Offline";

function PreloadContent(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [offline, setOffline] = useState(false);
    const preloadingContent = useSelector(state => state.loadingContentDone);
    const authorsMenu = useSelector(state => state.menu?.authors);
    const illustratorsMenu = useSelector(state => state.menu?.illustrators);
    const footerMenu = useSelector(state => state.menu?.footer);
    const pagesMenu = useSelector(state => state.menu?.pages);
    const sectionsMenu = useSelector(state => state.menu?.sections);
    const seriesMenu = useSelector(state => state.menu?.series);
    const seriesArchiveMenu = useSelector(state => state.menu?.seriesArchive);
    const topics = useSelector(state => state.menu?.topics);
    const regions = useSelector(state => state.menu?.regions);
    const latestIssue = useSelector(state => state.latestIssue);
    const seoImage = useSelector(state => state.seoImage);
    const blogPageId = useSelector(state => state.systemPages.blog);


    

    const { refetch: refetchStaticMenu } = useQuery(GQL_Query.GET_STATIC_MENU_CONTENT, {     
 
        onCompleted(data) {
            dispatch(SetAuthorsMenu(JSON.parse(data.menuAutorinnen.json)))
            dispatch(SetIllustratorsMenu(JSON.parse(data.menuIllustratorinnen.json)))
            dispatch(SetSeriesMenu(data.series.nodes))
            dispatch(SetSeriesArchiveMenu(data.serie))
            dispatch(SetTopicsMenu(data.rubriken.nodes))
            dispatch(SetRegionsMenu(data.regions.nodes))
            if(navigator.onLine) refetchStaticMenu({fetchPolicy: "network-only"})         
        },
      });

      
    const { refetch: refetchIssueColors } = useQuery(GQL_Query.GET_LATEST_ISSUE_COLORS, {
        onError(err) {
          // Error handling here 
          //console.log(err.message);      
        },
        onCompleted(data) {
          dispatch(SetLatestIssue(data.readingSettings.latestMagazineColors))
          if(navigator.onLine) refetchIssueColors({fetchPolicy: "network-only"})  
        }
      });

       
    

    const { refetch: refetchFooterMenu, data: FooterMenuData } = useQuery(
    GQL_Query.GET_WP_MENU_BY_LOCATION,
    {
        variables: { location: "FOOTER_MENU" },
        onError(err) {
        // Error handling here
        //console.log(err.message);
        },
        onCompleted(data) {          
        dispatch(SetFooterMenu(data.menuItems.nodes))  
        if(navigator.onLine) refetchFooterMenu({fetchPolicy: "network-only"})        
        },
    }
    );

    useEffect(() => {
      if(FooterMenuData) dispatch(SetFooterMenu(FooterMenuData.menuItems.nodes))      
    }, [FooterMenuData])

   const { refetch: refetchSectionsMenu } = useQuery(GQL_Query.GET_WP_MENU_BY_LOCATION, {
        variables: { location: "SECTIONS_MENU" },
        onError(err) {
            // Error handling here
            //console.log(err.message);
          },       
        onCompleted(data) {             
            dispatch(SetSectionsMenu(data.menuItems.nodes))
            if(navigator.onLine) refetchSectionsMenu({fetchPolicy: "network-only"}) 
        },
    });

    const {refetch: refetchPagesMenu } = useQuery(GQL_Query.GET_WP_MENU_BY_LOCATION, {
        variables: { location: "PAGES_MENU" },
        onError(err) {
            // Error handling here
            //console.log(err.message);
          },       
        onCompleted(data) {
            dispatch(SetPagesMenu(data.menuItems.nodes))
            if(navigator.onLine) refetchPagesMenu({fetchPolicy: "network-only"}) 
        },
    });

    dispatch(SetSeoFallbackImage('/og-fallback-image.png'))

    const { refetch: refetchBlogPageId} = useQuery(GQL_Query.GET_NEWS_PAGE_ID, {
        onCompleted(data) {
          dispatch(SetBlogPage(data.readingSettings.blogPage.pageID))
          if(navigator.onLine) refetchBlogPageId({fetchPolicy: "network-only"})  
        },
      });

    useEffect(() => {
      if(!preloadingContent) {
        //console.log("preloading...")
        if(authorsMenu && illustratorsMenu && footerMenu && pagesMenu && sectionsMenu && seriesMenu && seriesArchiveMenu && topics && regions && latestIssue && seoImage && blogPageId) dispatch(LoadingContentDone())
        //dispatch(LoadingContentDone())
      } else {
        //console.log("preloading done.")
      }
        
      
    }, [authorsMenu , illustratorsMenu , footerMenu , pagesMenu , sectionsMenu , seriesMenu , seriesArchiveMenu , topics , regions , latestIssue , seoImage, blogPageId, preloadingContent])

    useEffect(() => {
      //console.log("entry path")
      dispatch(SetEntryPath(location.pathname))
      
      if(!navigator.onLine) setOffline(true)
    }, [])

    

    
    if(offline) return <Offline/>
    if(preloadingContent) return props.children
    return (<Loading/>)
}

export default PreloadContent
