import React, { useState, useRef } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import client from "../../../services/apollo/client";
import SingleEvent from "./SingleEvent"
import Loading from "../../utility/Loading";
import Error from "../Error"
import Footer from "../../layout/Footer"
import NoEvents from "./NoEvents"
import { Helmet } from "react-helmet-async";
import HTML2React from "html2react";
import {Link} from "react-router-dom";

function Events() {
  const pageInfo = useRef(null);
  const [events, setEvents] = useState([]);
  const { data, loading, error, refetch } = useQuery(GQL_Query.GET_EVENTS, {    
    variables: { limit: 10 },
    client: client,
    onError(err) {
      // Error handling here
      //console.log(err.message);
    },
    onCompleted(data) {      
      setEvents(data.events.nodes)
      pageInfo.current = data.events.pageInfo;
      if(navigator.onLine) refetch({fetchPolicy: "network-only"})
    },
  });

  const [LoadEvents, {loading: loadingMore, refetch: refetchMore}] = useLazyQuery(GQL_Query.GET_EVENTS, {
    client: client,
    onError(err) {
      // Error handling here
      //console.log(err.message);
    },
    onCompleted(data) {
      setEvents(events.concat(data.events.nodes));
      if(navigator.onLine) refetchMore({fetchPolicy: "network-only"})
      pageInfo.current = data.events.pageInfo;
    },
  });

  const LoadMore = () => {
    LoadEvents({
      variables: { limit: 10, after: pageInfo.current.endCursor },
    });
  };

  if (loading) return <Loading/>
  if (error) return <Error/>  
  
  return (
    <>
    <Helmet>{HTML2React(data.seo.contentTypes.event.archive.fullHead)}</Helmet>
    
    <section className="section-header has-red-background-color has-white-color">
        <h2 className="h3 is-uppercase text-center has-font__sectra-grotesk-medium">
          Events
        </h2>
    </section>

    <div className="grid-container">
        <div className="inner-content grid-x grid-margin-x grid-padding-x" style={{"maxWidth" : "100vw"}}>
            <main className="main news-events small-12 has-font__sectra-grotesk cell" role="main">
              {events.length === 0 ? <NoEvents/> : 
                events.map(event => (
                  <SingleEvent key={event.id} event={event}/>
                ))}
                <section className="article-footer rep-margin-bottom-100 grid-x">
                {pageInfo.current?.hasNextPage && (
                    <div className="cell">
                      <button
                        onClick={LoadMore}
                        className={`has-red-background-color ${loadingMore && `loading-more`}`}
                      >
                        Mehr laden
                      </button>
                    </div>
                )}
                  <div className="cell">
                    <Link to="/event-archiv" className="button has-red-background-color">
                      Event-Archiv
                    </Link>
                  </div>
                </section>
                 
            </main>
        </div> 
    </div>
	
  <Footer/>
  </>
  );
}

export default Events;
