import React from "react";
import {Link} from "react-router-dom"
import { useSelector } from "react-redux";

function MenuFooter({closeMenu}) {
  const color = useSelector((state) => state.latestIssue);
  const footerMenu = useSelector((state) => state.menu.footer) 
 
  return (
      <div className="menu-footer full-width" style={{ background: color.mainColor}}>
          <div className="menu-footer__container">
              <ul id="footer-menu" className="menu__footer no-bullet has-small-font-size">
              {footerMenu.map(item => <li key={item.id} className="menu-item">{item.connectedNode ? <Link onClick={()=> closeMenu()} to={item.connectedNode.node.uri}>{item.label}</Link> : <a href={item.url} target="_blank" rel="noopener noreferrer">{item.label}</a>}</li>)}                  
              </ul>
          </div>        
      </div>
  )
}

export default MenuFooter
