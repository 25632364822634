import React from 'react'
import HTML2React from "html2react";
import { useQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import { Link } from "react-router-dom"

function NoEvents() {

    const { data, loading, refetch } = useQuery(GQL_Query.GET_NO_EVENTS_FEEDBACK, {          
        onError(err) {
          // Error handling here
          //console.log(err.message);
        },
        onCompleted(data) {
          //console.log(data)
          if(navigator.onLine) refetch({fetchPolicy: "network-only"})
        },
      });

      const ReplaceLinks = (props) => {
        if(props.href?.includes("http")) {
            return <a {...props} target="_blank" rel="noopener noreferrer"/>
        }
        else {
          return <Link {...props} to={props.href} />
        }
      }

    if(loading) return (null)

    return (
        <>
        {data.acfOptionsFrontend.staticBlocks.blockEventsNoEvents && HTML2React(data.acfOptionsFrontend.staticBlocks.blockEventsNoEvents.content, { a: ReplaceLinks, })}
        </>
    )
}

export default NoEvents
