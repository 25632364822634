import React from "react";
import { useQuery } from "@apollo/client";
import GQL_Query from "../../../../services/graphql/queries";
import Error from "../../Error";
import HTML2React from "html2react";
import RelatedReportage from "../RelatedReportage";
import Footer from "../../../layout/Footer";
import Loading from "../../../utility/Loading";
import { Helmet } from "react-helmet-async";
import Toolbar from "./Toolbar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../../../css/LazyLoadImage.css";

function Content({ slug, isLocked, isFree, hasEmailPass, emailPassToken }) {
  //console.log("-- Reportage/Content/index.js --")
  const selectedSize = useSelector((state) => state.fontSize);
  const { loading, error, data, refetch } = useQuery(
    GQL_Query.GET_REPORTAGE_CONTENT_BY_SLUG,
    {
      //fetchPolicy: "cache-and-network",
      variables: { slug: slug },
      onCompleted(data) {
        //console.log(data)
        if (navigator.onLine) refetch({ fetchPolicy: "network-only" });
      },
    }
  );

  const ReplaceLinks = (props) => {
    //console.log(props.href);
    if (props.href?.includes("http")) {
      return <a {...props} target="_blank" rel="noopener noreferrer"/>; // external links
    } else if (props.href?.includes("#")) {
      return <a {...props} />; // anchor links
    } else {
      return <Link {...props} to={props.href} />;
    }
  };

  const Image = (image) => {
    return <LazyLoadImage
        alt={image.alt}
        src={image.src}
        srcSet={image.srcSet}
        width={image.width}
        height={image.height}
        style={image.style}
        effect="opacity"
    />;
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data.reportage) return <Error />;

  return (
    <>
      <Toolbar reportage={data.reportage} hasEmailPass={hasEmailPass} emailPassToken={emailPassToken} />
      <Helmet>{HTML2React(data.reportage.seo.fullHead)}</Helmet>
      <div
        className={`reportage${isLocked ? ` user-has-valid-subscription` : ""}`}
      >
        <article
          itemType="https://schema.org/Article"
          className={data.reportage.reportageFontFamilyClass}
        >
          <section
            className={`entry-content grid-container user-has-${selectedSize}-font-size`}
            itemProp="text"
            id="reportage-content-section"
          >
            <div className="grid-x grid-padding-x grid-margin-x">
              <div className="cell">
                {isLocked &&
                  HTML2React(data.reportage.content, {
                    a: ReplaceLinks,
                    img: Image
                  })}
                {isFree &&
                  HTML2React(data.reportage.contentFree, {
                    a: ReplaceLinks,
                    img: Image
                  })}
                {hasEmailPass &&
                  HTML2React(data.reportage.contentEmailpass, {
                    a: ReplaceLinks,
                    img: Image
                  })}
              </div>
            </div>
          </section>
          <section
            className="reportage-footer full-width"
            style={{ background: data.reportage.reportageFooterColor }}
          >
            <div className="grid-container">
              <div className="grid-x grid-padding-x grid-margin-x align-center">
                <div className="cell text-center reportage-footer__title">
                  <p className="has-font__sectra-grotesk">Aus dem Archiv</p>
                  <div className="border-bottom"></div>
                </div>
              </div>
              <div className="grid-x grid-padding-x grid-margin-x align-center">
                {data.reportage.relatedReportagen?.map((rep) => (
                  <RelatedReportage key={rep.postId} reportage={rep} />
                ))}
              </div>
            </div>
          </section>
        </article>
      </div>
      <Footer color={data.reportage.reportageColors?.mainColor} />
    </>
  );
}

export default Content;
