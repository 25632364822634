import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {SetFontSize} from "../../../../../services/redux/actions";


function FontSizeMenu({toggleFontSizeMenu}) {
    const dispatch = useDispatch();    
    const selectedSize = useSelector((state) => state.fontSize);    
    const element = document.querySelector("#reportage-content-section")

    const setFontSizeLarge = () => {        
        if (element.classList.contains("user-has-medium-font-size")) {
            element.classList.remove("user-has-medium-font-size")
        }
        element.classList.add("user-has-large-font-size");
        dispatch(SetFontSize("large"));
        toggleFontSizeMenu();
    }

    const setFontSizeMedium = () => {       
        if (element.classList.contains("user-has-large-font-size")) {
            element.classList.remove("user-has-large-font-size")
        }
        element.classList.add("user-has-medium-font-size");
        dispatch(SetFontSize("medium"));
        toggleFontSizeMenu();
    }

    const resetFontSize = () => {
        if (element.classList.contains("user-has-large-font-size")) {
            element.classList.remove("user-has-large-font-size")
        }
        if (element.classList.contains("user-has-medium-font-size")) {
            element.classList.remove("user-has-medium-font-size")
        }
        dispatch(SetFontSize("standard"));
        toggleFontSizeMenu();
    }

    return (
        <div className="toolbar__submenu text-center">
            <div className="description">Schriftgrösse</div>
            <span onClick={setFontSizeLarge} className={`set-fontsize__large ${selectedSize === "large" && `has-bold-font-weight`}`}>Gross</span>
            <span onClick={setFontSizeMedium} className={`set-fontsize__medium ${selectedSize === "medium" && `has-bold-font-weight`}`}>Mittel</span>
            <span onClick={resetFontSize} className={`set-fontsize__standard ${selectedSize === "standard" && `has-bold-font-weight`}`}>Standard</span>
        </div>
    )
}

export default FontSizeMenu
