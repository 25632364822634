import React from 'react'
import {Link } from "react-router-dom"

function LayerSubmenu({handleMenuSelection, closeMenu, menu}) {
    

    return (
        <div className="submenu">
            <ul className="submenu__children">
                <div className="submenu__children--container">
                    <div className="submenu__children--container-meta">
                        <small className="taxonomy-name has-dark-gray-color">{menu.taxonomyNameSingular}</small>
                        <h3 className="has-font__sectra-grotesk-medium has-black-color">{menu.name}<a onClick={() => handleMenuSelection()} className="close-submenu"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 45.27L466.73 0 256 210.73 45.27 0 0 45.27 210.73 256 0 466.73 45.27 512 256 301.27 466.73 512 512 466.73 301.27 256z"></path></svg></a></h3>
                        <small className="term-count has-red-color">{menu.childrenCount} Texte</small>
                    </div>

                    {menu.children.nodes.map(item => <li key={item.id} className="submenu__child menu-item">
                        <Link onClick={() => closeMenu()} to={item.uri}><span>{item.name} <small>{item.count}</small></span></Link>
                    </li>)}

                </div>
            </ul>
        </div>
)
}

export default LayerSubmenu
