import React from 'react'
import { useQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import Footer from "../../layout/Footer";
import Loading from "../../utility/Loading";
import Error from "../Error";
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

function Topics() {
    const seoImage = useSelector(state => state.seoImage);
    
    const { data, loading, error, refetch } = useQuery(GQL_Query.GET_TOPICS, {       
        onCompleted(data) {          
          //console.log(data)
          if(navigator.onLine) refetch({fetchPolicy: "network-only"})  
        },
      });
    
      
    if (loading) return <Loading/>
    if (error) return <Error/>
    

    return (
        <>
        <Helmet>
            <title>Themen - Reportagen</title>
            <meta name="description" content="Die Themen aller Reportagen im Überblick."/>
            <meta property="og:locale" content="de_DE"/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content="Themen - Reportagen"/>
            <meta property="og:url" content={window.location.href}/>
            <meta property="og:description" content="Die Themen aller Reportagen im Überblick."/>
            <meta property="og:site_name" content="Reportagen"/>
            <meta property="article:publisher" content="https://www.facebook.com/reportagen"/>
            <meta property="og:image" content={seoImage} />
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@reportagench"/>
        </Helmet>
        <div className="border-top"></div>
        <div className="grid-container taxonomy-list__container">
            <div className="inner-content grid-x grid-margin-x grid-padding-x">
                    <div className="taxonomy-list cell">
                        <h3 className="has-font__sectra-grotesk-medium has-black-color">Themen</h3>

                        {data.rubriken.nodes.map(rubrik => <div key={rubrik.id} className="">

                            <div className="submenu has-font__sectra-grotesk">
                                <Link className="submenu__parent menu-item" to={rubrik.uri}>{rubrik.name} <small>{rubrik.count}</small></Link>
                                <ul className="submenu__children submenu__children--primary no-bullet">
                                    {rubrik.children.nodes.map((item) => <li className="submenu__child menu-item" key={item.id}><Link  to={item.uri}>{item.name} <small>{item.count}</small></Link></li>)}
                                </ul>

                            </div>

                        </div>)}

                    </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default Topics
