import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { OpenMenu, CloseMenu } from "../../../services/redux/actions";
import "../../../css/Accessibility.css";

function Header() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((state) => state.isMenuOpen);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const entryPath = useSelector((state) => state.path.entry);
  const loadingContentDone = useSelector((state) => state.loadingContentDone);
  const user = useSelector((state) => state.userData);
  const [showBackButton, setShowBackButton] = useState(false);

  history.listen((location, action) => {
    if (history.action === "POP" && history.location.pathname === entryPath) {
      setShowBackButton(false);
    } else {
      setShowBackButton(true);
    }
  });

  const goToLastPage = () => {
    history.goBack();
  };

  const closeMenu = () => {
    if (isMenuOpen) {
      document.body.classList.remove("menu-open");
      document.querySelector(".menu-container").classList.remove("visible");
      dispatch(CloseMenu());
    }
  };

  const openMenu = () => {
    if (!isMenuOpen && loadingContentDone) {
      document.body.classList.add("menu-open");
      document.body.classList.remove("scroll-down");
      dispatch(OpenMenu());
      setTimeout(() => {
        const menuContainer = document.querySelector(".menu-container");
        if (menuContainer) menuContainer.classList.add("visible");
      }, 10);
    }
  };

  const toggleMenu = () => {
    isMenuOpen ? closeMenu() : openMenu();
  };

  //console.log(history)
  //console.log(location)

  return (
    <header className="page-header" role="banner">
      <div
        className={`top-bar trigger-menu-wrapper has-text-decoration-none ${
          isMenuOpen ? "menu-open" : "menu-close"
        }`}
        id="top-bar-menu"
      >
        <a
          className="back-button"
          style={!showBackButton ? { visibility: "hidden" } : {}}
          onClick={goToLastPage}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 279.09 512">
            <path
              d="M261.59,512a17.46,17.46,0,0,1-12.23-5L5.27,268.51a17.5,17.5,0,0,1,0-25L249.36,5a17.5,17.5,0,1,1,24.46,25L42.54,256,273.82,482a17.5,17.5,0,0,1-12.23,30Z"
              fill="#00001b"
            />
          </svg>
        </a>

        <div className="logo">
          <Link className="home-link" to="/" onClick={() => closeMenu()}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425.2 59.97">
              <defs>
                <clipPath id="A" transform="translate(-5.67 -7.09)">
                  <path d="M5.67 7.09h425.2v59.97H5.67z" fill="none"></path>
                </clipPath>
              </defs>
              <g clipPath="url(#A)" fill="#1d1d1b">
                <path d="M55.9 48.73h19.74l.42.41v9.58l-.42.42H45.07l-.41-.42V1.25l.41-.41h30.26l.41.41v9.58l-.41.42H55.9l-.42.41v12.08l.42.42h18.74a.39.39 0 0 1 .42.41v9.58a.4.4 0 0 1-.42.42H55.9l-.42.41v13.33zm48.29-12.91h-7.92l-.41.41v22.49l-.42.42h-10l-.42-.42V1.25l.42-.41h18.74c10.24 0 17.49 6.66 17.49 17.49a17.08 17.08 0 0 1-17.49 17.49m-.42-24.57h-7.5l-.41.41v13.33l.41.42h7.5c4.08 0 6.66-3 6.66-7.08 0-3.83-2.33-7.08-6.66-7.08m22.51 18.74c0 8.41.33 16.32 4.16 21.9 3.08 4.5 8.33 8.08 16.66 8.08s13.57-3.58 16.66-8.08c3.83-5.58 4.16-13.49 4.16-21.9s-.33-16.33-4.16-21.91C160.66 3.59 155.42 0 147.09 0s-13.58 3.59-16.66 8.08c-3.83 5.58-4.16 13.5-4.16 21.91m30.31 0c0 5.58.09 12.32-1.75 15.24a9.09 9.09 0 0 1-15.49 0c-1.83-2.92-1.75-9.66-1.75-15.24s-.08-12.33 1.75-15.24a9.08 9.08 0 0 1 15.49 0c1.84 2.91 1.75 9.66 1.75 15.24"></path>
              </g>
              <path
                d="M217 .83l-.42.42v9.58l.42.41h11.66l.41.42v47.06l.42.41h9.99l.42-.41V11.66l.42-.42h11.66l.41-.41V1.25l-.41-.42H217zm49.9 34.82l5.33-22.9h.5l5.33 22.9-.33.33h-10.49zM265.33.84l-.42.41-14.75 57.47.42.42h10.24l.67-.67 2.75-11.74.33-.33h15.83l.33.33 2.75 11.74.66.67h10.25l.41-.42-14.74-57.47-.41-.41z"
                fill="#1d1d1b"
              ></path>
              <g clipPath="url(#A)" fill="#1d1d1b">
                <path d="M318.69 10.41a9 9 0 0 1 9.08 8.75l.42.42h9.91l.41-.42C338.51 8.08 330.77 0 318.69 0c-8.33 0-13.58 3.58-16.66 8.08-3.83 5.58-4.16 13.5-4.16 21.91s.33 16.32 4.16 21.9c3.08 4.5 8.33 8.08 16.66 8.08 12 0 19.82-6.91 19.82-19.82V27.07l-.41-.41h-19.49l-.42.41v9.16l.42.42h8.91l.42.42v3.08c0 6-3.34 9.41-9.25 9.41a8.56 8.56 0 0 1-7.75-4.33c-1.83-2.92-1.74-9.66-1.74-15.24s-.09-12.33 1.74-15.24a8.57 8.57 0 0 1 7.75-4.34m39.64 38.32h19.74l.42.41v9.58l-.42.42h-30.61l-.42-.42V1.25l.42-.41h30.23l.42.41v9.58l-.42.42h-19.36l-.41.41v12.08l.41.42h18.74a.39.39 0 0 1 .42.41v9.58a.4.4 0 0 1-.42.42h-18.74l-.41.41v13.33z"></path>
              </g>
              <path
                d="M414.79.83l-.42.42v35.56h-.58L400.96 1.25l-.42-.42h-12.41l-.41.42v57.47l.41.41h10l.42-.41v-34.9h.58l12.99 34.9.42.41h12.24l.42-.41V1.25l-.42-.42h-9.99z"
                fill="#1d1d1b"
              ></path>
              <g clipPath="url(#A)" fill="#1d1d1b">
                <path d="M15.33 35.4h-4.09l-.41.42v22.9l-.42.42h-10l-.42-.42V1.25L.41.84h18.75c10.32 0 17.49 6.66 17.49 17.49 0 6.91-3.58 12.07-9.83 15.32v.42l11.24 24.57-.5.5h-11l-.5-.5-10.32-22.82zm-4.08-10h7.5c4.33 0 6.66-3 6.66-7.08 0-3.83-2.33-7.08-6.66-7.08h-7.5l-.41.41v13.34zm179.91 10h-4.08l-.42.42v22.9l-.42.42h-10l-.42-.42V1.25l.42-.41h18.74c10.33 0 17.49 6.66 17.49 17.49 0 6.91-3.58 12.07-9.83 15.32v.42l11.25 24.57-.5.5h-11l-.5-.5-10.32-22.82zm-4.08-10h7.49c4.33 0 6.67-3 6.67-7.08 0-3.83-2.34-7.08-6.67-7.08h-7.49l-.42.41v13.34z"></path>
              </g>
            </svg>
          </Link>
        </div>
        <nav className="top-bar__navigation">
          <div className="menu-icons">
            {!isLoggedIn ? (
              <a
                href="https://shop.reportagen.com"
                target="_blank"
                className="subscription-link has-font__sectra-grotesk is-uppercase is-uppercase has-letter-spacing"
                aria-label="Abo"
              >
                <span>Abo</span>
              </a>
            ) : null}
            {isLoggedIn ? (
              <Link
                to="/konto"
                onClick={() => closeMenu()}
                className="user-initials has-font__gt-sectra-medium has-text-decoration-none is-uppercase has-letter-spacing"
              >
                <span>
                  {user.firstName && user.lastName
                    ? `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`
                    : user.username.slice(0, 2)}
                </span>
              </Link>
            ) : (
              <Link to="/login" onClick={() => closeMenu()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 409.56 512.59"
                >
                  <path d="M392.06,512.59h0l-187.28-.06-187.27.05h0A17.5,17.5,0,0,1,1.38,488.26L77,309.51a181.5,181.5,0,0,1,0-256.4A179.52,179.52,0,0,1,204.72,0h.19A179.44,179.44,0,0,1,332.53,53.09c70.69,70.7,70.68,185.73,0,256.43q37.83,89.37,75.65,178.76a17.49,17.49,0,0,1-16.11,24.31ZM204.77,477.53l160.87.05Q333,400.36,300.28,323.17a34.84,34.84,0,0,1,7.49-38.4c57.05-57,57.05-149.88,0-206.93A144.66,144.66,0,0,0,204.89,35h-.15A146.16,146.16,0,0,0,59,181.3a145.27,145.27,0,0,0,42.78,103.45,34.9,34.9,0,0,1,7.48,38.39L43.91,477.57Z" />
                </svg>
              </Link>
            )}

            <button
              className="main-menu-toggle"
              title="Menu"
              aria-expanded={isMenuOpen ? "true" : "false"}
              onClick={toggleMenu}
            >
              {isMenuOpen ? (
                <svg
                  id="close-menu"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M280.75,256,506.87,29.87A17.5,17.5,0,1,0,482.13,5.12L256,231.25,29.87,5.12A17.5,17.5,0,1,0,5.13,29.87L231.25,256,5.13,482.13a17.49,17.49,0,1,0,24.74,24.74L256,280.75,482.13,506.87a17.49,17.49,0,0,0,24.74-24.74Z" />
                </svg>
              ) : (
                <svg
                  id="open-menu"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 636.03 512.01"
                >
                  <path d="M327.55 35H17.5a17.5 17.5 0 0 1 0-35h310.05a17.5 17.5 0 0 1 0 35zm0 477H17.5a17.5 17.5 0 0 1 0-35h310.05a17.5 17.5 0 0 1 0 35zm0-159H17.5a17.5 17.5 0 0 1 0-35h310.05a17.5 17.5 0 0 1 0 35zm0-159H17.5a17.5 17.5 0 0 1 0-35h310.05a17.5 17.5 0 0 1 0 35z" />
                  <path
                    d="M339.81,405.11c106.86,0,193.79-86.94,193.79-193.8S446.67,17.51,339.81,17.51,146,104.45,146,211.31s86.94,193.8,193.8,193.8"
                    fill="#fff"
                  />
                  <path d="M630.9,482.13,501.34,352.57a18.32,18.32,0,0,0-2.62-2.16,210.42,210.42,0,0,0,52.38-139.1C551.1,94.8,456.32,0,339.81,0s-211.3,94.79-211.3,211.3,94.79,211.3,211.3,211.3a210.34,210.34,0,0,0,134.36-48.34,17,17,0,0,0,2.43,3.05L606.16,506.88a17.5,17.5,0,0,0,24.74-24.75ZM339.81,387.61c-97.21,0-176.3-79.09-176.3-176.3S242.6,35,339.81,35,516.1,114.1,516.1,211.31,437,387.61,339.81,387.61Z" />
                </svg>
              )}
            </button>

            {/* {isMenuOpen ? (
              <div
                tabIndex="0"
                role="button"
                id="close-main-menu"
                className="trigger-menu"
                aria-label="Menü schließen"
                onClick={() => closeMenu()}
              >
                <svg
                  id="close-menu"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M280.75,256,506.87,29.87A17.5,17.5,0,1,0,482.13,5.12L256,231.25,29.87,5.12A17.5,17.5,0,1,0,5.13,29.87L231.25,256,5.13,482.13a17.49,17.49,0,1,0,24.74,24.74L256,280.75,482.13,506.87a17.49,17.49,0,0,0,24.74-24.74Z" />
                </svg>
              </div>
            ) : (
              <div
                tabIndex="0"
                className="trigger-menu"
                id="open-main-menu"
                aria-label="Menü öffnen"
                onClick={() => openMenu()}
                role="button"
              >
                <svg
                  id="open-menu"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 636.03 512.01"
                >
                  <path d="M327.55 35H17.5a17.5 17.5 0 0 1 0-35h310.05a17.5 17.5 0 0 1 0 35zm0 477H17.5a17.5 17.5 0 0 1 0-35h310.05a17.5 17.5 0 0 1 0 35zm0-159H17.5a17.5 17.5 0 0 1 0-35h310.05a17.5 17.5 0 0 1 0 35zm0-159H17.5a17.5 17.5 0 0 1 0-35h310.05a17.5 17.5 0 0 1 0 35z" />
                  <path
                    d="M339.81,405.11c106.86,0,193.79-86.94,193.79-193.8S446.67,17.51,339.81,17.51,146,104.45,146,211.31s86.94,193.8,193.8,193.8"
                    fill="#fff"
                  />
                  <path d="M630.9,482.13,501.34,352.57a18.32,18.32,0,0,0-2.62-2.16,210.42,210.42,0,0,0,52.38-139.1C551.1,94.8,456.32,0,339.81,0s-211.3,94.79-211.3,211.3,94.79,211.3,211.3,211.3a210.34,210.34,0,0,0,134.36-48.34,17,17,0,0,0,2.43,3.05L606.16,506.88a17.5,17.5,0,0,0,24.74-24.75ZM339.81,387.61c-97.21,0-176.3-79.09-176.3-176.3S242.6,35,339.81,35,516.1,114.1,516.1,211.31,437,387.61,339.81,387.61Z" />
                </svg>
              </div>
            )} */}
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
