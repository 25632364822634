const bookmarksReducer = (state = [], action) => {
  switch (action.type) {
    case "ADD_BOOKMARK":
      //state.push(action.payload);
      state = [action.payload].concat(state)
      return state;

    case "DEL_BOOKMARK":
      state = state.filter((item) => item.bookmarkPostId !== action.payload.bookmarkPostId);
      return state;
    default:
      return state;
  }
};

export default bookmarksReducer;