import React, {useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { CloseSnackbar } from "../../../services/redux/actions";
//import "../../../css/Snackbar.css"

function Snackbar() {
  const dispatch = useDispatch();
  
  const showSnackbar = useSelector((state) => state.snackbar.showSnackbar);
  const snackbarMessage = useSelector((state) => state.snackbar.snackbarMessage);

  let timer;
  function handleTimeout() {     
    timer = setTimeout(() => {
      dispatch(CloseSnackbar());
    }, 3000);
  }

  function handleClose() {
    clearTimeout(timer);
    dispatch(CloseSnackbar());
  }

  useEffect(() => {
    if (showSnackbar) {
      handleTimeout();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [showSnackbar, timer]);
  
    return (
        <>
        {showSnackbar && (
        <div className="snackbar"> 
            <span className="has-small-font-size">{snackbarMessage}</span>
            <svg onClick={handleClose} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 45.27L466.73 0 256 210.73 45.27 0 0 45.27 210.73 256 0 466.73 45.27 512 256 301.27 466.73 512 512 466.73 301.27 256z"></path></svg>
        </div>
        )}
        </>
    )
}

export default Snackbar
