import React from "react";
import { Link } from "react-router-dom";

function MagazineMenu({ handleMenuSelection, closeMenu }) {
  return (
    <div className="submenu">
      <a className="submenu__parent menu-item">
        <span>Magazin</span>
      </a>
      <ul className="submenu__children">
        <li className="submenu__child menu-item" onClick={() => closeMenu()}>
          <Link to="/magazin">
            <span>Ausgaben</span>
          </Link>
        </li>
        <li
          className="submenu__child menu-item"
          onClick={() => handleMenuSelection("authorMenu")}
        >
          <span tabIndex="0" role="button">
            Autor:innen
          </span>
        </li>
        <li
          className="submenu__child menu-item"
          onClick={() => handleMenuSelection("illustratorMenu")}
        >
          <span tabIndex="0" role="button">
            Illustrator:innen
          </span>
        </li>
      </ul>
    </div>
  );
}

export default MagazineMenu;
