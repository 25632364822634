import React from "react";
import { useQuery } from "@apollo/client";
import GQL_Query from "../../../../services/graphql/queries";
import Error from "../../Error";
import HTML2React from "html2react";
import RelatedReportage from "../RelatedReportage";
import Footer from "../../../layout/Footer";
import Loading from "../../../utility/Loading";
import { Helmet } from "react-helmet-async";
import EmailPass from "./EmailPass";
import { Link } from "react-router-dom";

function Paywall({ slug, isLoggedIn, isSubscribed }) {
  const { loading, error, data, refetch } = useQuery(
    GQL_Query.GET_REPORTAGE_BY_SLUG,
    {
      //fetchPolicy: "cache-and-network",
      variables: { slug: slug },
      onError(err) {
        //console.log(err)
      },
      onCompleted(data) {
        //console.log(data.reportage);
        //console.log("initial query done, refetching now")
        if (navigator.onLine) {
          //console.log("online, refetch")
          refetch({ fetchPolicy: "network-only" });
        }
      },
    }
  );

  const ReplaceLinks = (props) => {
    if (props.href?.includes("http")) {
      return <a {...props} target="_blank" rel="noopener noreferrer" />;
    } else {
      return <Link {...props} to={props.href} />;
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data.reportage) return <Error />;

  return (
    <>
      <Helmet>{HTML2React(data.reportage.seo.fullHead)}</Helmet>
      <div className="reportage">
        <article
          itemType="https://schema.org/Article"
          className={data.reportage.reportageFontFamilyClass}
        >
          <section className="entry-content grid-container" itemProp="text">
            <div className="grid-x grid-padding-x grid-margin-x">
              <div className="cell">
                {HTML2React(data.reportage.contentPaywall, { a: ReplaceLinks })}

                {!isLoggedIn && (
                  <>
                    {HTML2React(
                      data.acfOptionsFrontend.staticBlocks.blockPaywallDefault
                        .content,
                      { a: ReplaceLinks }
                    )}{" "}
                    <EmailPass
                      title={data.reportage.title}
                      id={data.reportage.emailPassToken}
                      slug={slug}
                    />
                  </>
                )}
                {isLoggedIn &&
                  !isSubscribed &&
                  HTML2React(
                    data.acfOptionsFrontend.staticBlocks
                      .blockPaywallNoSubscription.content,
                    { a: ReplaceLinks }
                  )}
              </div>
            </div>
          </section>
          <section
            className="reportage-footer full-width"
            style={{ background: data.reportage.reportageFooterColor }}
          >
            <div className="grid-container">
              <div className="grid-x grid-padding-x grid-margin-x align-center">
                <div className="cell text-center reportage-footer__title">
                  <p className="has-font__sectra-grotesk">Aus dem Archiv</p>
                  <div className="border-bottom"></div>
                </div>
              </div>
              <div className="grid-x grid-padding-x grid-margin-x align-center">
                {data.reportage.relatedReportagen?.map((rep) => (
                  <RelatedReportage key={rep.postId} reportage={rep} />
                ))}
              </div>
            </div>
          </section>
        </article>
      </div>
      <Footer color={data.reportage.reportageColors?.mainColor} />
    </>
  );
}

export default Paywall;
