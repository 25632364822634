import React from "react";
import { useQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
//import shopClient from "../../../services/apollo/shopClient";
import client from "../../../services/apollo/client";
import HTML2React from "html2react";
import { Helmet } from "react-helmet-async";
import Error from "../Error";
import Footer from "../../layout/Footer"
import Toolbar from "./Toolbar";
import Loading from "../../utility/Loading";
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../../css/LazyLoadImage.css";

function Event(props) {
  const { slug } = props.match.params;

  const { loading, error, data, refetch } = useQuery(GQL_Query.GET_EVENT_BY_SLUG, {
    variables: { slug: slug },
    client: client,
    onError(err) {
      // Error handling here
      //console.log(err.message);
    },
    onCompleted(data) {      
      if(navigator.onLine) refetch({fetchPolicy: "network-only"})
    },
  });

  const ReplaceLinks = (props) => {
    if(props.href?.includes("http")) {
        return <a {...props} target="_blank" rel="noopener noreferrer"/>
    }
    else {
      return <Link {...props} to={props.href} />
    }
  }

  const Image = (image) => {
    return <LazyLoadImage
        alt={image.alt}
        src={image.src}
        srcSet={image.srcSet}
        width={image.width}
        height={image.height}
        style={image.style}
        effect="opacity"
    />;
  };

  if (loading) return <Loading/>
  if (error) return <Error/>
  if (!data.event) return <Error/>
  return (
    <>
      <Toolbar title={data.event.title} slug={slug} />
    <Helmet>{HTML2React(data.event.seo.fullHead)}</Helmet>
      <div className="grid-container">
        <div className="inner-content grid-x grid-margin-x grid-padding-x">
          <main
            className="main news-events small-12 has-font__sectra-grotesk cell"
            role="main"
          >
            <article
              itemProp="event"
              itemScope
              itemType="https://schema.org/Event"
            >
              <section className="article-header has-red-color">
                <p
                  className="date has-medium-font-weight"
                  itemProp="startDate"
                  content={data.event.eventStartDateISO}
                >
                  {HTML2React(data.event.eventDate)}
                </p>
                <h2
                  className="entry-title single-title has-font__sectra-grotesk has-medium-font-weight"
                  itemProp="name"
                >
                  {data.event.title}
                </h2>
                <p className="lead has-medium-font-weight">{data.event.excerptRaw}</p>
              </section>

              <section className="entry-content" itemProp="text">
                {data.event.featuredImage && (
                  <figure className="featured-image">
                    <LazyLoadImage
                      width={data.event.featuredImage.node.mediaDetails.width}
                      height={data.event.featuredImage.node.mediaDetails.height}
                      src={data.event.featuredImage.node.mediaItemUrl}
                      className="attachment-full size-full wp-post-image"
                      alt={data.event.featuredImage.node.altText}
                      srcSet={data.event.featuredImage.node.srcSet}
                      sizes={data.event.featuredImage.node.sizes}
                      wrapperProps={{style: {height: "100%", width: "100%", display: "flex" }}}
                      effect="opacity"
                    />
                    {data.event.featuredImage.node.caption && (
                      <figcaption>
                        {HTML2React(data.event.featuredImage.node.caption, { a: ReplaceLinks })}
                      </figcaption>
                    )}
                  </figure>
                )}
                {HTML2React(data.event.content, {
                  a: ReplaceLinks,
                  img: Image,
                })}
              </section>

              <section className="article-footer">
                <Link to="/events" className="button has-red-background-color">
                  Alle Events
                </Link>
              </section>
            </article>
          </main>
        </div>
      </div>
    <Footer/>
    </>
  );
}

export default Event;
