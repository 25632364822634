import React from "react";
import HTML2React from "html2react";
import { Link } from "react-router-dom";

function Series({ serie }) {
  const url = `/reportage/${serie.slug}`;  
  return (
    <Link to={url} className={`cell has-text-decoration-none related-serie ${serie?.searchResultClass && serie.searchResultClass}`}>
     {HTML2React(serie.searchResultMarkup)}
    </Link>
  );
}

export default Series;
