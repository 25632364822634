const menuReducer = (state = {
    footer: null,
    topics: null,
    regions: null,
    series: null,
    seriesArchive: null,
    pages: null,
    sections: null,
    authors: null,
    illustrators: null
  }, action) => {
    switch (action.type) {
      case "SET_FOOTER_MENU":
        return {
            ...state,           
            footer: action.payload
          };
      case "SET_SECTIONS_MENU":
        return {
            ...state,
            sections: action.payload
          };
      case "SET_PAGES_MENU":
        return {
            ...state,
            pages: action.payload
          };
      case "SET_AUTHORS_MENU":
        return {
            ...state,
            authors: action.payload
          };
      case "SET_ILLUSTRATORS_MENU":
        return {
            ...state,
            illustrators: action.payload
          };
      case "SET_SERIES_MENU":
        return {
            ...state,
            series: action.payload
          };
      case "SET_SERIES_ARCHIVE_MENU":
        return {
            ...state,
            seriesArchive: action.payload
          };
      case "SET_TOPICS_MENU":
        return {
            ...state,
            topics: action.payload
          };
      case "SET_REGIONS_MENU":
        return {
            ...state,
            regions: action.payload
          };           
                
      default:
        return state;
    }
  };
  
  export default menuReducer;
  