import gql from "graphql-tag";

const queries = {
  GET_WP_MENU_BY_LOCATION: gql`
    query GET_WP_MENU_BY_LOCATION($location: MenuLocationEnum) {
      menuItems(where: { location: $location, parentId: "" }) {
        nodes {
          cssClasses
          id
          label
          url
          connectedNode {
            node {
              uri
            }
          }
          childItems {
            nodes {
              id
              connectedNode {
                node {
                  uri
                }
              }
              label
            }
          }
        }
      }
    }
  `,
  GET_STATIC_MENU_CONTENT: gql`
    query GET_STATIC_MENU_CONTENT {
      menuAutorinnen {
        id
        json
      }
      menuIllustratorinnen {
        id
        json
      }
      series(where: { childless: true, parent: 0 }, first: 100) {
        nodes {
          count
          name
          slug
          id
        }
      }
      serie(id: "275", idType: DATABASE_ID) {
        name
        slug
        id
        count
        childrenCount
        taxonomyName
        taxonomyNameSingular
        children(first: 100) {
          nodes {
            count
            name
            slug
            id
            uri
          }
        }
      }
      rubriken(where: { parent: 0 }, first: 100) {
        nodes {
          name
          slug
          id
          count
          childrenCount
          taxonomyName
          taxonomyNameSingular
          children(first: 100) {
            nodes {
              count
              name
              slug
              id
              uri
            }
          }
        }
      }
      regions(where: { parent: 0 }, first: 100) {
        nodes {
          name
          slug
          id
          count
          childrenCount
          taxonomyName
          taxonomyNameSingular
          children(first: 100) {
            nodes {
              count
              name
              slug
              id
              uri
            }
          }
        }
      }
    }
  `,

  GET_LATEST_ISSUE_COLORS: gql`
    query GET_LATEST_ISSUE_COLORS {
      readingSettings {
        id
        latestMagazineColors {
          id
          backgroundColorLight
          backgroundColorMedium
          mainColor
        }
      }
    }
  `,
  GET_FRONTPAGE_ID: gql`
    query GET_FRONTPAGE_ID {
      readingSettings {
        id
        frontPage {
          id
          pageID
        }
      }
    }
  `,
  GET_HOME_CONTENT: gql`
    query GET_HOME_CONTENT($id: ID!) {
      page(id: $id, idType: DATABASE_ID) {
        id
        content
        seo {
          fullHead
        }
      }
    }
  `,

  GET_MAGAZINES: gql`
    query GET_MAGAZINES($limit: Int, $after: String) {
      magazines(first: $limit, after: $after) {
        nodes {
          id
          title
          link
          slug
          ACF_Magazin_Fields {
            pdfDownloadUrl
            issue
            month
            year
            productLink
            cover {
              link
              id
              sourceUrl
              srcSet
              mediaDetails {
                width
                height
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
      seo {
        contentTypes {
          magazin {
            archive {
              fullHead
            }
          }
        }
      }
      acfOptionsFrontend {
        nextIssueDate {
          nextIssueDate
        }
      }
    }
  `,
  GET_MAGAZINE_BY_SLUG: gql`
    query GET_MAGAZINE_BY_SLUG($slug: ID!) {
      generalSettings {
        language
      }
      seo {
        social {
          twitter {
            cardType
            username
          }
        }
      }
      magazin(id: $slug, idType: SLUG) {
        id
        content(format: RENDERED)
        title
        link
        slug
        magazineColors {
          backgroundColorLight
          backgroundColorMedium
          mainColor
        }
        ACF_Magazin_Fields {
          pdfDownloadUrl
          productLink
          cover {
            sourceUrl
            srcSet
            id
            mediaDetails {
              width
              height
            }
          }
          issue
          month
          year
        }
        allRelatedSeries {
          authors
          excerpt
          illustrators
          postId
          slug
          title
          searchResultClass
          searchResultMarkup
        }
        databaseId
        magazinId
        seo {
          fullHead
        }
      }
    }
  `,
  GET_AUTHORS: gql`
    query GET_AUTHORS {
      menuAutorinnen {
        id
        json
      }
      seo {
        contentTypes {
          autorin {
            archive {
              fullHead
            }
          }
        }
      }
    }
  `,
  GET_AUTHOR_BY_SLUG: gql`
    query GET_AUTHOR_BY_SLUG(
      $slugID: ID!
      $slugString: String
      $limit: Int
      $after: String
    ) {
      autorin(id: $slugID, idType: SLUG) {
        id
        title
        databaseId
        authorTextCount {
          reportageCount
          seriesCount
        }
        ACF_AutorIn_Fields {
          biography
        }
        seo {
          fullHead
        }
      }
      reportages(
        where: { autorinSlug: $slugString }
        first: $limit
        after: $after
      ) {
        nodes {
          id
          slug
          searchResultClass
          searchResultMarkup
          uri
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `,
  GET_ILLUSTRATORS: gql`
    query GET_ILLUSTRATORS {
      menuIllustratorinnen {
        id
        json
      }
      seo {
        contentTypes {
          illustrator {
            archive {
              fullHead
            }
          }
        }
      }
    }
  `,
  GET_ILLUSTRATOR_BY_SLUG: gql`
    query GET_ILLUSTRATOR_BY_SLUG($slugID: ID!) {
      illustrator(id: $slugID, idType: SLUG) {
        id
        title
        databaseId
        illustratorImageCount {
          illustrationCount
          infographicCount
        }
        ACF_IllustratorIn_Fields {
          biography
        }
        seo {
          fullHead
        }
        imagesBy {
          maxNumPages
          totalImages
          imagesJson
        }
      }
    }
  `,
  GET_REPORTAGE_BASE_OPTIONS: gql`
    query GET_REPORTAGE_BASE_OPTIONS($slug: ID!) {
      reportage(id: $slug, idType: SLUG) {
        id
        emailPassToken
        slug
        Reportagen_Options {
          locked
        }
      }
    }
  `,
  GET_REPORTAGE_BY_SLUG: gql`
    query GET_REPORTAGE_BY_SLUG($slug: ID!) {
      reportage(id: $slug, idType: SLUG) {
        title
        id
        contentPaywall
        emailPassToken
        reportageFontFamilyClass
        reportageFooterColor
        relatedReportagen {
          searchResultClass
          markup
          slug
          postId
        }
        reportageColors {
          mainColor
        }
        seo {
          fullHead
        }
      }
      acfOptionsFrontend {
        staticBlocks {
          blockPaywallDefault {
            ... on ReusableBlock {
              id
              content
            }
          }
          blockPaywallNoSubscription {
            ... on ReusableBlock {
              id
              content
            }
          }
        }
      }
    }
  `,
  GET_REPORTAGE_CONTENT_BY_SLUG: gql`
    query GET_REPORTAGE_CONTENT_BY_SLUG($slug: ID!) {
      reportage(id: $slug, idType: SLUG) {
        id
        databaseId
        title
        slug
        content(format: RENDERED)
        contentFree
        contentEmailpass
        reportageFontFamilyClass
        reportageFooterColor
        searchResultClass
        searchResultMarkup
        relatedReportagen {
          searchResultClass
          markup
          slug
          postId
        }
        reportageColors {
          mainColor
        }
        seo {
          fullHead
        }
      }
    }
  `,
  GET_NEWS_PAGE_ID: gql`
    query GET_NEWS_PAGE_ID {
      readingSettings {
        id
        blogPage {
          id
          pageID
        }
      }
    }
  `,
  GET_NEWS: gql`
    query GET_NEWS($limit: Int, $after: String, $id: ID!) {
      page(id: $id, idType: DATABASE_ID) {
        id
        seo {
          fullHead
        }
      }
      posts(first: $limit, after: $after) {
        nodes {
          id
          content
          date
          title
          slug
          excerptRaw
          featuredImage {
            node {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
              srcSet
              sizes(size: LARGE)
              title
              altText
              caption
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `,
  GET_MORE_NEWS: gql`
    query GET_MORE_NEWS($limit: Int, $after: String) {
      posts(first: $limit, after: $after) {
        nodes {
          id
          date
          title
          slug
          excerptRaw
          featuredImage {
            node {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
              srcSet
              sizes(size: LARGE)
              title
              altText
              caption
            }
          }
          content
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `,
  GET_NEWS_BY_SLUG: gql`
    query GET_NEWS_BY_SLUG($slug: ID!) {
      post(id: $slug, idType: SLUG) {
        id
        date
        slug
        title
        content(format: RENDERED)
        excerptRaw
        featuredImage {
          node {
            mediaItemUrl
            mediaDetails {
              height
              width
            }
            srcSet
            sizes(size: LARGE)
            title
            altText
            caption
          }
        }
        seo {
          fullHead
        }
      }
    }
  `,
  GET_SEARCH_KEYWORD_IDS: gql`
    query Search($term: String, $perPage: Int) {
      reportagenSearch(perPage: $perPage, searchString: $term) {
        foundResults
        maxNumPages
        resultPageIDs
      }
    }
  `,
  GET_SEARCH_REPORTAGES_BY_IDLIST: gql`
    query Search($ids: [ID]) {
      reportages(where: { in: $ids }) {
        nodes {
          id
          searchResultClass
          searchResultMarkup
          uri
        }
      }
    }
  `,
  GET_LOGIN_TEXT_BLOCK: gql`
    query GET_ACCOUNT_TEXT_BLOCKS {
      acfOptionsFrontend {
        staticBlocks {
          blockUserWelcomeLogin {
            ... on ReusableBlock {
              id
              content
            }
          }
        }
      }
    }
  `,
  GET_ACCOUNT_TEXT_BLOCKS: gql`
    query GET_ACCOUNT_TEXT_BLOCKS {
      acfOptionsFrontend {
        staticBlocks {
          blockUserAd {
            ... on ReusableBlock {
              id
              content
            }
          }
          blockUserWelcomeAccount {
            ... on ReusableBlock {
              id
              content
            }
          }
        }
      }
      magazines(first: 1) {
        nodes {
          id
          title
          uri
          ACF_Magazin_Fields {
            month
            year
            cover {
              sourceUrl
              srcSet
              id
            }
          }
        }
      }
    }
  `,
  GET_VIEWER: gql`
    query GET_VIEWER {
      viewer {
        databaseId
        email
        firstName
        lastName
        username
        subscriptionAccessStatus
        subscriptions {
          expires
          plan_id
          status
          title
        }
        bookmarks {
          bookmarkPostId
          bookmarkSlug
          bookmarkTitle
          bookmarkClass
          bookmarkMarkup
        }
        jwtAuthExpiration
        jwtAuthToken
        jwtRefreshToken
      }
    }
  `,
  GET_USER_ABO_STATUS: gql`
    query GET_USER_ABO_STATUS {
      viewer {
        id
        subscriptionAccessStatus
      }
    }
  `,
  GET_USER_MAILCHIMP_STATUS: gql`
    query GET_USER_MAILCHIMP_STATUS($email: String) {
      getMailchimpStatusByEmail(userEmail: $email)
    }
  `,
  GET_LATEST_EVENTS: gql`
    query GET_LATEST_EVENTS {
      events(first: 2, where: { orderby: { field: EVENT_DATE, order: ASC } }) {
        nodes {
          id
          eventDate
          title
          excerptRaw
          slug
        }
      }
    }
  `,
  GET_EVENTS: gql`
    query GET_EVENTS($limit: Int, $after: String) {
      events(
        first: $limit
        after: $after
        where: {orderby: {field: EVENT_DATE, order: ASC}, futureEvents: true}
      ) {
        nodes {
          id
          eventDate
          eventStartDateISO
          title
          uri
          excerptRaw
          content(format: RENDERED)
          featuredImage {
            node {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
              srcSet
              sizes(size: LARGE)
              title
              altText
              caption
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
      seo {
        contentTypes {
          event {
            archive {
              fullHead
            }
          }
        }
      }
    }
  `,
  GET_PAST_EVENTS: gql`
    query GET_PAST_EVENTS($limit: Int, $after: String) {
      events(
        first: $limit
        after: $after
        where: {orderby: {field: EVENT_DATE, order: DESC}, futureEvents: false}
      ) {
        nodes {
          id
          eventDate
          eventStartDateISO
          title
          uri
          excerptRaw
          content(format: RENDERED)
          featuredImage {
            node {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
              srcSet
              sizes(size: LARGE)
              title
              altText
              caption
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
      seo {
        contentTypes {
          event {
            archive {
              fullHead
            }
          }
        }
      }
    }
  `,
  GET_NO_EVENTS_FEEDBACK: gql`
    query GET_NO_EVENTS_FEEDBACK {
      acfOptionsFrontend {
        staticBlocks {
          blockEventsNoEvents {
            ... on ReusableBlock {
              id
              content
            }
          }
        }
      }
    }
  `,
  GET_REGION_BY_SLUG: gql`
    query GET_REGION_BY_SLUG($slug: ID!, $limit: Int, $after: String) {
      region(idType: SLUG, id: $slug) {
        id
        name
        taxReportageCount {
          reportagenCount
          articleCount
        }
        contentNodes(first: $limit, after: $after) {
          nodes {
            ... on Reportage {
              slug
              searchResultClass
              searchResultMarkup
              id
              uri
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
        seo {
          fullHead
        }
      }
    }
  `,
  GET_REGIONS: gql`
    query GET_REGIONS {
      regions(where: { parent: 0 }, first: 100) {
        nodes {
          name
          slug
          id
          uri
          count
          childrenCount
          taxonomyName
          taxonomyNameSingular
          children(first: 100) {
            nodes {
              count
              name
              slug
              id
              uri
            }
          }
        }
      }
    }
  `,
  GET_TOPIC_BY_SLUG: gql`
    query GET_TOPIC_BY_SLUG($slug: ID!, $limit: Int, $after: String) {
      rubrik(idType: SLUG, id: $slug) {
        id
        name
        taxReportageCount {
          reportagenCount
          articleCount
        }
        contentNodes(first: $limit, after: $after) {
          nodes {
            ... on Reportage {
              slug
              searchResultClass
              searchResultMarkup
              id
              uri
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
        seo {
          fullHead
        }
      }
    }
  `,
  GET_TOPICS: gql`
    query GET_TOPICS {
      rubriken(where: { parent: 0 }, first: 100) {
        nodes {
          name
          slug
          id
          uri
          count
          childrenCount
          taxonomyName
          taxonomyNameSingular
          children(first: 100) {
            nodes {
              count
              name
              slug
              id
              uri
            }
          }
        }
      }
    }
  `,
  GET_SERIES_BY_SLUG: gql`
    query GET_SERIES_BY_SLUG($slug: ID!, $limit: Int, $after: String) {
      serie(id: $slug, idType: SLUG) {
        name
        id
        taxReportageCount {
          reportagenCount
          articleCount
        }
        contentNodes(first: $limit, after: $after) {
          nodes {
            ... on Reportage {
              slug
              searchResultClass
              searchResultMarkup
              uri
              id
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
        seo {
          fullHead
        }
      }
    }
  `,
  GET_SERIES: gql`
    query GET_SERIES {
      series(where: { parent: 0 }, first: 100) {
        nodes {
          name
          slug
          id
          uri
          count
          childrenCount
          taxonomyName
          taxonomyNameSingular
          children(first: 100) {
            nodes {
              count
              name
              slug
              id
              uri
            }
          }
        }
      }
    }
  `,
  GET_SPECIAL_BY_SLUG: gql`
    query GET_SPECIAL_BY_SLUG($slug: ID!, $limit: Int, $after: String) {
      special(id: $slug, idType: SLUG) {
        name
        id
        taxReportageCount {
          reportagenCount
          articleCount
        }
        contentNodes(first: $limit, after: $after) {
          nodes {
            ... on Reportage {
              slug
              searchResultClass
              searchResultMarkup
              uri
              id
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
        seo {
          fullHead
        }
      }
    }
  `,
  GET_SPECIALS: gql`
    query GET_SPECIALS {
      specials(where: { parent: 0 }, first: 100) {
        nodes {
          name
          slug
          id
          uri
          count
          taxonomyName
          taxonomyNameSingular
          children(first: 100) {
            nodes {
              count
              name
              slug
              id
              uri
            }
          }
        }
      }
    }
  `,
  GET_EVENT_BY_SLUG: gql`
    query GET_EVENT_BY_SLUG($slug: ID!) {
      event(id: $slug, idType: SLUG) {
        id
        eventDate
        eventStartDateISO
        title
        excerptRaw
        content(format: RENDERED)
        featuredImage {
          node {
            mediaItemUrl
            mediaDetails {
              height
              width
            }
            srcSet
            sizes(size: LARGE)
            title
            altText
            caption
          }
        }
        seo {
          fullHead
        }
      }
    }
  `,
  GET_PAGE_BY_SLUG: gql`
    query GET_PAGE_BY_SLUG($slug: ID!) {
      page(id: $slug, idType: URI) {
        pageOptions {
          bodyFont
          pageHeaderAlignment
          pageHeaderFontSize
          pageHeaderWidth
        }
        id
        title
        content
        seo {
          fullHead
        }
      }
    }
  `,
  GET_BOOKMARKS_FEEDBACK: gql`
    query GET_BOOKMARKS_FEEDBACK {
      acfOptionsFrontend {
        staticBlocks {
          blockBookmarksEmpty {
            ... on ReusableBlock {
              id
              content
            }
          }
          blockBookmarksLoggedout {
            ... on ReusableBlock {
              id
              content
            }
          }
          blockBookmarksNoSubscription {
            ... on ReusableBlock {
              id
              content
            }
          }
        }
      }
    }
  `,
  GET_FALLBACK_SEO_IMAGE: gql`
    query GET_FALLBACK_SEO_IMAGE {
      seo {
        openGraph {
          defaultImage {
            mediaItemUrl
            id
          }
        }
      }
    }
  `,
  GET_REDIRECTS: gql`
    query GET_REDIRECTS {
      acfOptionsRedirects {
        redirects {
          id
          allRedirects {
            slug
            destination
          }
        }
      }
    }
  `,
  GET_FAQ_PAGE: gql`
    query GET_FAQ_PAGE {
      page(id: "faq", idType: URI) {
        id
        title
        content
        excerptRaw
        seo {
          fullHead
        }
        pageOptions {
          bodyFont
          pageHeaderAlignment
          pageHeaderWidth
          pageHeaderFontSize
        }
      }
    }`,
  GET_NEXT_ISSUE_DATE: gql`
    query GET_NEXT_ISSUE_DATE {
      acfOptionsFrontend {
        nextIssueDate {
          nextIssueDate
        }
      }
    }`
};

export default queries;
