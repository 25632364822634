import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "@apollo/client/link/context";
import GraphQL from "../graphql/config";
import { onError } from "apollo-link-error";
import { persistCacheSync, persistCache, IonicStorageWrapper, LocalStorageWrapper } from 'apollo3-cache-persist';


let inMemoryToken;

function login(jwtAuthToken, jwtAuthExpiration) {
  inMemoryToken = {
    token: jwtAuthToken,
    expiry: jwtAuthExpiration,
  };

  //console.log("AuthToken:", inMemoryToken.token);
}

function logout() {
  inMemoryToken = null;
  localStorage.removeItem("refreshToken");
}

const logoutLink = onError(
  ({ networkError, graphQLErrors, ApolloError, response, operation }) => {
    //console.log("response:", response);
    //console.log("operation:", operation);
    if (networkError) {
      //console.log("NETWORK ERROR!");
      //console.log(networkError);
    }    
    if (ApolloError) {
      //console.log("APOLLO ERROR!");
    }
  }
);

const httpLink = createHttpLink({
  uri: GraphQL.gqlShop,
});



const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = inMemoryToken.token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const cache = new InMemoryCache();


// persistCacheSync({
//   cache,
//   //storage: new IonicStorageWrapper(Storage),
//   storage: new LocalStorageWrapper(window.localStorage)
// });


// Apollo GraphQL client
const authClient = new ApolloClient({
  link: logoutLink.concat(authLink.concat(httpLink)),
  //link: authLink.concat(httpLink),
  //link: httpLink,
  cache,
  connectToDevTools: false
});

export { login, logout, authClient, inMemoryToken };
