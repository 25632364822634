const systemPagesReducer = (state = {
    blog: null,
    home: null,
  }, action) => {
    switch (action.type) {
      case "SET_BLOG_PAGE":
        return {
            ...state,           
            blog: action.payload
          };
      case "SET_HOME_PAGE":
        return {
            ...state,
            home: action.payload
          };     
                
      default:
        return state;
    }
  };
  
  export default systemPagesReducer;
  