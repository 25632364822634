import React from 'react'
import HTML2React from "html2react";
import { Link } from "react-router-dom"

const ReplaceLinks = (props) => {
  if(props.href?.includes("http")) {
      return <a {...props} target="_blank" rel="noopener noreferrer"/>
  }
  else {
    return <Link {...props} to={props.href} />
  }
}

function AdBlock({markup}) {
    return (
        <div className="cell">
        {HTML2React(markup, { a: ReplaceLinks, })}
      </div>
    )
}

export default AdBlock
