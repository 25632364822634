import React from "react";
import { useSelector } from "react-redux";
import UserBookmarks from "./UserBookmarks";
import Feedback from "./Feedback";
import Loading from "../../utility/Loading";

function Bookmarks() { 
  const isLoggedIn = useSelector((state) => state.isLoggedIn);  
  const loginCheckDone = useSelector((state) => state.loginCheckDone);
  const bookmarks = useSelector((state) => state.bookmarks)
  const isSubscribed = useSelector((state) => state.userData.subscriptionAccessStatus)

  if (!loginCheckDone) return <Loading/>
  if (!isLoggedIn) return <Feedback notLoggedIn/>
  if (!isSubscribed) return <Feedback notSubscribed/>
  if (bookmarks.length < 1 ) return <Feedback noBookmarks/>
  return <UserBookmarks bookmarks={bookmarks}/>
 
}

export default Bookmarks;
