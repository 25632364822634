import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function UserMenu({ slug }) {
  const latestIssue = useSelector((state) => state.latestIssue);

  return (
    <div
      className="user-menu border-top"
      style={{ backgroundColor: `${latestIssue.mainColor}` }}
    >
      <div className="grid-container">
        <div className="grid-x grid-margin-x grid-padding-x">
          <div className="cell user-menu__links">
            <Link to="/konto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 409.56 512.59"
              >
                <path d="M392.06,512.59h0l-187.28-.06-187.27.05h0A17.5,17.5,0,0,1,1.38,488.26L77,309.51a181.5,181.5,0,0,1,0-256.4A179.52,179.52,0,0,1,204.72,0h.19A179.44,179.44,0,0,1,332.53,53.09c70.69,70.7,70.68,185.73,0,256.43q37.83,89.37,75.65,178.76a17.49,17.49,0,0,1-16.11,24.31ZM204.77,477.53l160.87.05Q333,400.36,300.28,323.17a34.84,34.84,0,0,1,7.49-38.4c57.05-57,57.05-149.88,0-206.93A144.66,144.66,0,0,0,204.89,35h-.15A146.16,146.16,0,0,0,59,181.3a145.27,145.27,0,0,0,42.78,103.45,34.9,34.9,0,0,1,7.48,38.39L43.91,477.57Z" />
              </svg>
            </Link>

            <Link to="/bookmarks">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 346.22 511.99"
              >
                <path d="M328.71 512a17.47 17.47 0 0 1-13-5.82L173.1 347.28 35 501.16l-4.5 5A17.51 17.51 0 0 1 0 494.48v-477A17.51 17.51 0 0 1 17.51 0h311.2a17.51 17.51 0 0 1 17.51 17.51v477A17.52 17.52 0 0 1 328.71 512zM173.1 312.25a35 35 0 0 1 26.07 11.65l112 124.84V35H35v413.74q56-62.43 112-124.85a35.07 35.07 0 0 1 26.1-11.64z"></path>
              </svg>
            </Link>

            <Link
              className="user-menu__links--text has-text-decoration-none"
              aria-label="FAQ"
              to="/faq"
            >
              <span className="has-font__sectra-grotesk">?</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserMenu;
