import React from 'react'
import "../../css/Loading.css"

function Loading() {
    return (
        <div className="loading-container">
            <div className="header-placeholder"></div>
            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export default Loading
