import React from 'react'
import { useDispatch } from 'react-redux';
import { OpenSnackbar } from '../../../../../services/redux/actions';

function SharingMenu({ title, slug, toggleSharingMenu, emailPassToken}) {

    let sharingUrl = "https://reportagen.com/reportage/" + slug + "?t=" + emailPassToken;
    const dispatch = useDispatch();
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(sharingUrl);
            dispatch(OpenSnackbar("Link kopiert"));
        } catch (err) {
            //console.log('Failed to copy: ', err);
        }
    }

    return (
        <div className="toolbar__submenu text-center">
            <div className="description">Teilen</div>
            <a target="_blank" rel="noopener noreferrer" onClick={toggleSharingMenu} href={`mailto:?subject=Lesetipp: ${title} in REPORTAGEN&body=${sharingUrl}`}>E-Mail</a>
            <a target="_blank" rel="noopener noreferrer" onClick={toggleSharingMenu} href={`https://api.whatsapp.com/send?text=${sharingUrl}`}>WhatsApp</a>
            <a target="_blank" rel="noopener noreferrer" onClick={toggleSharingMenu} href={`https://www.facebook.com/sharer/sharer.php?u=${sharingUrl}`}>Facebook</a>
            <a target="_blank" rel="noopener noreferrer" onClick={toggleSharingMenu} href={`https://www.linkedin.com/sharing/share-offsite/?url=${sharingUrl}`}>LinkedIn</a>
            <a target="_blank" rel="noopener noreferrer" onClick={copyToClipboard}>Link kopieren</a>
        </div>
    )
}

export default SharingMenu
