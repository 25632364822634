import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import GraphQL from "../graphql/config";
//import { Storage } from '@ionic/storage';
import { persistCacheSync, persistCache, IonicStorageWrapper, LocalStorageWrapper } from 'apollo3-cache-persist';

const httpLink = createHttpLink({
  uri: GraphQL.gqlUrl,
  useGETForQueries: true, // This line enables GET requests for queries
  headers: { 'content-type': 'application/x-www-form-urlencoded' },
});

const cache = new InMemoryCache({
  
});

persistCacheSync({
  cache,
  //storage: new IonicStorageWrapper(Storage),
  storage: new LocalStorageWrapper(window.localStorage),
});



// Apollo GraphQL client
const client = new ApolloClient({
  link: httpLink,
  cache,
  connectToDevTools: false
});

export default client;
