import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { CloseMenu } from "../../../services/redux/actions";
import GeneralMenu from "./GeneralMenu"
import AuthorsSubmenu from "./Submenus/AuthorsSubmenu"
import LayerSubmenu from "./Submenus/LayerSubmenu"



function Menu() {
    const dispatch = useDispatch()
    const isMenuOpen = useSelector((state) => state.isMenuOpen);
    const topics = useSelector((state) => state.menu.topics)
    const regions = useSelector((state) => state.menu.regions)
    const seriesArchive = useSelector(state => state.menu.seriesArchive)
    const authors = useSelector(state => state.menu.authors)
    const illustrators = useSelector(state => state.menu.illustrators)      
    const [currentContent, setCurrentContent] = useState("")    
    
      
    const handleMenuSelection = (menu) => {        
        if(menu) {            
            setCurrentContent(menu)
        }
        else {
            setCurrentContent("")
        }        
    }

    const closeMenu = () => {
        if (isMenuOpen) {
          document.body.classList.remove("menu-open")
          document.querySelector(".menu-container").classList.remove("visible") 
          dispatch(CloseMenu());        
        }   
      };

    if(!isMenuOpen) return null;

    return (               
        <div className={`menu-container ${isMenuOpen && "menu-open"}`}>
            <div className="menu">
                {currentContent === "authorMenu" && <AuthorsSubmenu title="Autor:innen" authors={authors} handleMenuSelection={handleMenuSelection} closeMenu={closeMenu}/>}
                {currentContent === "illustratorMenu" && <AuthorsSubmenu title="Illustrator:innen" authors={illustrators} handleMenuSelection={handleMenuSelection} closeMenu={closeMenu}/>}
                {currentContent === "seriesArchiveMenu" && <LayerSubmenu menu={seriesArchive} handleMenuSelection={handleMenuSelection} closeMenu={closeMenu}/>}
                {topics.map((topic) => currentContent === `${topic.slug}Menu` && <LayerSubmenu key={topic.id} menu={topic} handleMenuSelection={handleMenuSelection} closeMenu={closeMenu}/>)}
                {regions.map((region) => currentContent === `${region.slug}Menu` && <LayerSubmenu key={region.id} menu={region} handleMenuSelection={handleMenuSelection} closeMenu={closeMenu}/>)}
                {!currentContent && <GeneralMenu handleMenuSelection={handleMenuSelection} closeMenu={closeMenu} /> }                                 
            </div>
        </div>        
    )
}

export default Menu
