import React from "react";
import HTML2React from "html2react";
import { Link } from "react-router-dom"
import { useSelector } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../../css/LazyLoadImage.css";


function EditorContent({ content }) {

  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isSubscribed = useSelector(
      (state) => state.userData.subscriptionAccessStatus
  );
  
  const ReplaceLinks = (props) => {
    if(props.href?.includes("http")) {
        return <a {...props} target="_blank" rel="noopener noreferrer"/>
    }
    else {
      return <Link {...props} to={props.href} />
    }
  }
  const Image = (image) => {
    return <LazyLoadImage
        alt={image.alt}
        src={image.src}
        srcSet={image.srcSet}
        width={image.width}
        height={image.height}
        style={image.style}
        effect="opacity"
    />;
  };

  return (
    <section className={`front-teasers${isLoggedIn && isSubscribed ? ` user-has-valid-subscription` : ""}`} itemProp="text">
     {HTML2React(content, { a: ReplaceLinks, img: Image })}
    </section>
  );
}

export default EditorContent;
