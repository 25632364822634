import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom"
import { DelBookmark, OpenSnackbar } from "../../../services/redux/actions";
import GQL_Mutation from "../../../services/graphql/mutations";
import shopClient from "../../../services/apollo/shopClient";
import HTML2React from "html2react";
import Footer from "../../layout/Footer";
import { Helmet } from "react-helmet-async";
import { inMemoryToken } from "../../../services/apollo/authClient";
import UserMenu from "../../layout/UserMenu";

function UserBookmarks({bookmarks}) {
  const dispatch = useDispatch();
  const seoImage = useSelector(state => state.seoImage);  

  const [delBookmarkFromDB, { }] = useMutation(
    GQL_Mutation.DELETE_BOOKMARK,    {
      client: shopClient,
      onError(err) {
        // Error handling here
        //console.log(err.message);        
      },
      onCompleted(data) {
        // todo: check if deletion was successful 
        dispatch(OpenSnackbar(data.deleteBookmark.message))
      },
    }
  );

  const handleClick = (bookmark) => {    
    dispatch(DelBookmark({bookmarkPostId: bookmark.bookmarkPostId}));
    delBookmarkFromDB({variables: {
      user: inMemoryToken?.token,
      id: bookmark.bookmarkPostId
    }})
    
  }
    return (
        <>
        <Helmet>
          <title>Lesezeichen</title>
          <meta name="description" content="Gespeicherte Reportagen."/>
          <meta property="og:locale" content="de_DE"/>
          <meta property="og:type" content="article"/>
          <meta property="og:title" content="Gespeicherte Reportagen"/>
          <meta property="og:description" content="Gespeicherte Reportagen."/>
          <meta property="og:site_name" content="Reportagen"/>
          <meta property="article:publisher" content="https://www.facebook.com/reportagen"/>
          <meta property="og:image" content={seoImage}/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:site" content="@reportagench"/>
        </Helmet>
        <div className="bookmarks">
          <UserMenu/>
            <div className="grid-container">
                <div className="grid-x grid-margin-x grid-padding-x">
                    <div className="cell">
                        <h3 className="has-font__sectra-grotesk-medium">Lesezeichen</h3>
                        <p className="has-font__sectra-grotesk has-small-font-size has-dark-gray-color">Neu hinzugefügte Texte erscheinen zuoberst.</p>
                    </div>
                  
                        <div className="bookmarks__list cell">{bookmarks.map((bookmark,index) => (
                        <div className="bookmark-container" key={bookmark.bookmarkPostId}>
                            <Link
                                className={`cell has-text-decoration-none search-result ${bookmark?.bookmarkClass && bookmark.bookmarkClass}`} to={`reportage/${bookmark.bookmarkSlug}`}>{HTML2React(bookmark.bookmarkMarkup)}
                            </Link>
                            <div className="bookmark-actions">
                                <span className="bookmark-actions__icon delete-bookmark" title="Lesezeichen löschen" onClick={() => handleClick(bookmark)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 346.2 512"><path d="M328.7 512c-5 0-9.7-2.1-13-5.8L173.1 347.3 35 501.2l-4.5 5c-6.5 7.2-17.6 7.7-24.7 1.2A17.3 17.3 0 0 1 0 494.5v-477C0 7.8 7.9 0 17.5 0h311.2c9.7 0 17.5 7.8 17.5 17.5v477c0 9.7-7.8 17.5-17.5 17.5z"/></svg>
                                </span>
                            </div>
                        </div>
                        ))}</div>
                   
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default UserBookmarks
