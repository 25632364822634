import React, {useEffect, useState} from "react";

function Greeting({user}) {
    const [greeting, setGreeting] = useState("Guten Tag");

    useEffect(() => {
        const date = new Date()
        const hour = date.getHours()
    
        if (hour < 11) {
          setGreeting("Guten Morgen")
        }
        else if (hour < 17) {
          setGreeting("Guten Tag")
        }
        else {
          setGreeting("Guten Abend")
        }
        
      }, [])

    return (
        <div className="cell">   
        <h3 className="has-font__sectra-grotesk-medium">{greeting}, {user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.username}</h3>
        </div>
    )
}

export default Greeting
