import React from 'react'
import HTML2React from "html2react";
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../../css/LazyLoadImage.css";

function SingleEvent({event}) {

  const ReplaceLinks = (props) => {
    if(props.href?.includes("http")) {
        return <a {...props} target="_blank" rel="noopener noreferrer"/>
    }
    else {
      return <Link {...props} to={props.href} />
    }
  }

  const Image = (image) => {
    return <LazyLoadImage
        alt={image.alt}
        src={image.src}
        srcSet={image.srcSet}
        width={image.width}
        height={image.height}
        style={image.style}
        effect="opacity"
    />;
  };

  return (
            <article
              itemProp="event"
              itemScope
              itemType="https://schema.org/Event"
            >
              <section className="article-header has-red-color">
                <p
                  className="date has-medium-font-weight"
                  itemProp="startDate"
                  content={event.eventStartDateISO}
                >
                  {HTML2React(event.eventDate)}
                </p>
                <Link to={event.uri}>
                <h2
                  className="entry-title single-title has-font__sectra-grotesk has-medium-font-weight"
                  itemProp="name"
                >
                  {event.title}
                </h2>
                </Link>
                <p className="lead has-medium-font-weight">{event.excerptRaw}</p>
              </section>

              <section className="entry-content" itemProp="text">
                {event.featuredImage && (
                  <Link to={event.uri} className="has-text-decoration-none">
                  <figure className="featured-image">
                    <LazyLoadImage
                      width={event.featuredImage.node.mediaDetails.width}
                      height={event.featuredImage.node.mediaDetails.height}
                      src={event.featuredImage.node.mediaItemUrl}
                      className="attachment-full size-full wp-post-image"
                      alt={event.featuredImage.node.altText}
                      srcSet={event.featuredImage.node.srcSet}
                      sizes={event.featuredImage.node.sizes}
                      wrapperProps={{style: {height: "100%", width: "100%", display: "flex" }}}
                      effect="opacity"
                    />
                    {event.featuredImage.node.caption && (
                      <figcaption>
                        {HTML2React(event.featuredImage.node.caption, { a: ReplaceLinks })}
                      </figcaption>
                    )}
                  </figure>
                  </Link>
                )}
                {HTML2React(event.content, {
                  a: ReplaceLinks,
                  img: Image
                })}
              </section>
              
            </article>
  )
}

export default SingleEvent
