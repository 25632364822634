import React from 'react'
import { Link } from "react-router-dom";


function LinkedMenuItem({closeMenu, item}) {

    return (
        <li className={`menu-item ${item?.cssClasses && item.cssClasses.map(css => css).join(' ')}`}>
            {
            item?.connectedNode ? <Link onClick={() => closeMenu()} to={item?.connectedNode.node.uri}><span>{item?.label}</span></Link> :
            (item?.cssClasses && item?.cssClasses.includes("internal")) ? <Link onClick={() => closeMenu()} to={item?.url}><span>{item?.label}</span></Link> :
            <a href={item?.url} target="_blank" rel="noopener noreferrer"><span>{item?.label}</span></a>            
            }
        </li>
    )
}

export default LinkedMenuItem