import React, { useEffect } from "react";
import Welcome from "./Welcome";
import User from "./User";
import Abo from "./Abo";
import AdBlock from "./AdBlock";
import Greeting from "./Greeting";
import { Redirect, useHistory } from "react-router-dom";
import Loading from "../../utility/Loading";
import Footer from "../../layout/Footer";
import LatestIssue from "./LatestIssue";
import { useDispatch, useSelector } from "react-redux";
import {
  UserLoggedOut,
  DelUserData,
  SetUserData,
  OpenSnackbar,
} from "../../../services/redux/actions";
import { logout } from "../../../services/apollo/authClient";
import { authClient } from "../../../services/apollo/authClient";
import GQL_Query from "../../../services/graphql/queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import UserMenu from "../../layout/UserMenu";
import MailchimpSubscription from "./MailchimpSubscription";

function Account() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.userData);
  const loginCheckDone = useSelector((state) => state.loginCheckDone);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const seoImage = useSelector((state) => state.seoImage);

  const {
    loading: textLoading,
    data,
    refetch,
  } = useQuery(GQL_Query.GET_ACCOUNT_TEXT_BLOCKS, {
    onError(err) {
      // Error handling here
      //console.log(err.message);
    },
    onCompleted(data) {
      if (navigator.onLine) refetch({ fetchPolicy: "network-only" });
    },
  });

  const [loadUserData] = useLazyQuery(GQL_Query.GET_VIEWER, {
    fetchPolicy: "network-only",
    client: authClient,
    onError(err) {
      // Error handling here
      //console.log("onError:", err.message);
    },
    onCompleted(data) {
      const user = {
        id: data.viewer.id,
        email: data.viewer.email,
        username: data.viewer.username,
        firstName: data.viewer.firstName,
        lastName: data.viewer.lastName,
        subscriptionAccessStatus: data.viewer.subscriptionAccessStatus,
        subscriptions: data.viewer.subscriptions,
      };
      dispatch(SetUserData(user));
    },
  });

  useEffect(() => {
    if (isLoggedIn && navigator.onLine) {
      loadUserData();
    }
  }, [isLoggedIn]);

  const handleLogout = () => {
    logout();
    dispatch(DelUserData());
    localStorage.removeItem('rep_subscription');
    localStorage.setItem('rep_login',false);
    localStorage.removeItem('lastLoginCheck');
    dispatch(UserLoggedOut());
    dispatch(OpenSnackbar("Sie sind nun abgemeldet."));
    history.push("/");
  };

  if (!loginCheckDone) return <Loading />;
  if (!isLoggedIn) return <Redirect to={"/login"} />;
  //if (userLoading) return <Loading/>
  if (textLoading) return <Loading />;
  //if (!data.viewer) return <Error/>

  return (
    <>
      <Helmet>
        <title>Benutzerkonto - Reportagen</title>
        <meta name="description" content="Benutzerkonto für Reportagen." />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Benutzerkonto - Reportagen" />
        <meta property="og:description" content="Gespeicherte Reportagen." />
        <meta property="og:site_name" content="Reportagen" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/reportagen"
        />
        <meta property="og:image" content={seoImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@reportagench" />
      </Helmet>
      <div className="account">
        <UserMenu />
        <div className="grid-container">
          <div className="grid-x grid-margin-x grid-padding-x">
            <Greeting user={user} />
            {data.acfOptionsFrontend.staticBlocks.blockUserAd && (
              <AdBlock
                markup={
                  data.acfOptionsFrontend.staticBlocks.blockUserAd.content
                }
              />
            )}
            <Welcome
              msg={
                data.acfOptionsFrontend.staticBlocks.blockUserWelcomeAccount
                  ? data.acfOptionsFrontend.staticBlocks.blockUserWelcomeAccount
                      .content
                  : "Sie sind mit den folgenden Benutzerdaten angemeldet"
              }
            />
            <User user={user} />
            <Abo user={user} />
            <MailchimpSubscription user={user} />

            {/*<LatestIssue issue={data.magazines.nodes[0]} />*/}

            <div className="cell">
              <p>
                Alle Informationen zu Ihrem Benutzerkonto und zum Digitalzugang
                finden Sie in Ihrem Konto im{" "}
                <span className="has-font-style-italic">Reportagen</span>-Shop.
              </p>
              <p>
                <a
                  href="https://shop.reportagen.com/mein-konto/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Zum Shop-Konto
                </a>
              </p>
            </div>
            <div className="cell rep-margin-bottom-100">
              <p>
                <a onClick={handleLogout}>Abmelden</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Account;
