import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import EditorContent from "./EditorContent";
import Footer from "../../layout/Footer";
import Loading from "../../utility/Loading";
import { Helmet } from "react-helmet-async";
import HTML2React from "html2react";
import Error from "../Error";

function Home() {
  const [loadingComplete, setLoadingComplete] = useState(false);

  const { refetch: refetchFrontpageId } = useQuery(GQL_Query.GET_FRONTPAGE_ID, {
    onCompleted(data) {
      LoadContent({
        variables: { id: data.readingSettings.frontPage.pageID },
      });

      if (navigator.onLine) {
        refetchFrontpageId({ fetchPolicy: "network-only" });
        ForceReloadContent({
          variables: { id: data.readingSettings.frontPage.pageID },
        });
      }
    },
  });

  const [LoadContent, { error, data, loading }] = useLazyQuery(
    GQL_Query.GET_HOME_CONTENT
  );

  const [ForceReloadContent] = useLazyQuery(GQL_Query.GET_HOME_CONTENT, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && !loading) setLoadingComplete(true);
    if (error) setLoadingComplete(true);
  }, [data, loading, error]);

  if (!loadingComplete) return <Loading />;
  if (error) return <Error />;

  return (
    <>
      <Helmet>{HTML2React(data.page.seo.fullHead)}</Helmet>
      <div className="home-container">
        <main role="main">
          <article
            className="home"
            itemScope=""
            itemType="http://schema.org/WebPage"
          >
            <EditorContent content={data.page.content} />
          </article>
        </main>
      </div>
      <Footer />
    </>
  );
}

export default Home;
