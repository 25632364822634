import React, { useState, useEffect } from "react";
import Loading from "../../utility/Loading";
import Error from "../Error";
import { useQuery, useLazyQuery } from "@apollo/client";
import GQL_Query from "../../../services/graphql/queries";
import Footer from "../../layout/Footer";
import HTML2React from "html2react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "../../../css/LazyLoadImage.css";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

function Page(props) {
  const { slug } = props.match.params;
  const [redirect, setRedirect] = useState(false);

  const {
    error: redirectsError,
    loading: redirectsLoading,
    refetch: redirectsRefetch,
  } = useQuery(GQL_Query.GET_REDIRECTS, {
    onCompleted(data) {
      const res = data.acfOptionsRedirects.redirects.allRedirects.find(
        (r) => r.slug == slug
      );
      if (res) {
        window.location.href = res.destination;
        setRedirect(true);
      }
      if (navigator.onLine) redirectsRefetch({ fetchPolicy: "network-only" });
    },
  });

  const { data, error, loading, refetch } = useQuery(
    GQL_Query.GET_PAGE_BY_SLUG,
    {
      variables: { slug: slug },
      onError(err) {
        // Error handling here
        // console.log(err.message);
      },
      onCompleted(data) {
        // console.log(data);
        if (navigator.onLine) refetch({ fetchPolicy: "network-only" });
      },
    }
  );

  const ReplaceLinks = (props) => {
    if (props.href?.includes("http") || props.href?.includes("mailto")) {
      return <a {...props} target="_blank" rel="noopener noreferrer" />;
    } else {
      return <Link {...props} to={props.href} />;
    }
  };

  const Image = (image) => {
    return <LazyLoadImage
        alt={image.alt}
        src={image.src}
        srcSet={image.srcSet}
        width={image.width}
        height={image.height}
        style={image.style}
        effect="opacity"
    />;
  };

  if (redirect) return <Loading />;
  if (redirectsLoading) return <Loading />;
  if (redirectsError) return <Error />;
  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return <Loading />;
  if (!data.page) return <Error />;

  return (
    <>
      <Helmet>{HTML2React(data.page.seo.fullHead)}</Helmet>
      <div className="grid-container" style={{width: "100%"}}>
        <div className="inner-content grid-x grid-margin-x grid-padding-x">
          <main className="main small-12 large-12 medium-12 cell" role="main">
            <article
              className={`page ${data.page.pageOptions.bodyFont}`}
              itemScope=""
              itemType="http://schema.org/WebPage"
            >
              <header
                className={`article-header ${data.page.pageOptions.pageHeaderWidth} ${data.page.pageOptions.pageHeaderAlignment}`}
              >
                <h1
                  className={`page-title has-bold-font-weight ${data.page.pageOptions.bodyFont} ${data.page.pageOptions.pageHeaderFontSize}`}
                >
                  {data.page.title}
                </h1>
              </header>
              <section className="entry-content" itemProp="text">
                {HTML2React(data.page.content, { a: ReplaceLinks, img: Image })}
              </section>
            </article>
          </main>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Page;
