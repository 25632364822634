import React from 'react'

var styles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    background: "#ffffff",
    position: "absolute",
    top: 0
}

function Offline() {
    return (
        <div style={styles}>
            <h4>
                Sie sind derzeit offline.
            </h4>
        </div>
    )
}

export default Offline
